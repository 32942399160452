import * as React from 'react';
import { useSelector, useDispatch } from "react-redux"
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { currentInteractionSlice, TransferInteractionActionPayload } from "../store/currentInteraction/slice";
import { AppState } from '../store';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    }
}));


export default function TransferDialog({showTransfer, setShowTransfer}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { interactionId } = useSelector((state:AppState) => state.currentInteraction);
    const { interactions } = useSelector((state:AppState) => state.interaction);
    const { categories } = useSelector((state:AppState) => state.category);
    const { tenantId } = useSelector((state:AppState) => state.currentUser);

    let selectedCategoryId:string | null = null;
    for(let i = 0; i < interactions.length; ++i){
        if (interactions[i].id == interactionId){
            selectedCategoryId = interactions[i].categoryId;
        }
    }
    const categoryOptions:JSX.Element[] = [];
    if (categories){
        for (let i = 0; i < categories.length; ++i) {
            const value = categories[i];
            if (value.id === selectedCategoryId){
                continue;
            }
            categoryOptions.push(<MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>);
        }
    }
    const [newCategoryId, setNewCategoryId] = useState("");
    const handleExecuteTransfer = () => {      
        const payload:TransferInteractionActionPayload = {
            tenantId: tenantId!,
            interactionId: interactionId!,
            newCategoryId: newCategoryId
        };
        dispatch(currentInteractionSlice.asyncActions.transferInteraction(payload));
        setShowTransfer(false);
    }

    const handleNewCategoryIdChanged = (event) => {
        setNewCategoryId(event.target.value);
    }

    const handleOnClose = (_, reason) => {
        if (reason !== 'backdropClick') {
            setShowTransfer(false);
        }
    }

    return(
        <Dialog open={showTransfer} onClose={handleOnClose}>
            <DialogTitle>Transfer Interaction</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select the category to transfer to.
                </DialogContentText>
                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="new-category-label">New Category</InputLabel>
                        <Select
                            labelId="new-category-label"
                            value={newCategoryId}
                            onChange={handleNewCategoryIdChanged}>
                            <MenuItem value="">
                            <em>[Select Category]</em>
                            </MenuItem>
                            {categoryOptions}
                        </Select>
                        <FormHelperText>New category</FormHelperText>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowTransfer(false)} color="primary">Cancel</Button>
                <Button onClick={handleExecuteTransfer} color="primary">Transfer</Button>
            </DialogActions>
        </Dialog>
    )
}