import * as React from 'react';
import ForwardIcon from '@mui/icons-material/Forward';
import StopIcon from '@mui/icons-material/Stop';
import TransferInteractionDialog from './TransferInteractionDialog'
import ResolveInteractionDialog from './ResolveInteractionDialog'
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { AppState } from '../store';
import { useSelector } from "react-redux";

const AgentMediaChat = () => {
    const [showTransfer, setShowTransfer] = useState(false);
    const [showResolve, setShowResolve] = useState(false);
    const { tenants } = useSelector((state: AppState) => state.tenant);
    const { tenantId } = useSelector((state: AppState) => state.currentUser);
    const currentInteraction = useSelector((state: AppState) => state.currentInteraction);
    const currentTenant = tenants[tenantId!];
    
    const handleOpenCrm = () => {
        const urlToOpen = currentTenant.crmUrl
        .replace("%interactionId", encodeURIComponent(currentInteraction.interactionId!));
        window.open(urlToOpen, '_blank');
    }
    return <>
        <Tooltip title="Transfer">    
            <span>
                <IconButton aria-label="Transfer" onClick={() => setShowTransfer(true)} size="large">
                    <ForwardIcon />           
                </IconButton>    
            </span>
        </Tooltip>          
        <Tooltip title="Resolve">    
            <span>
                <IconButton aria-label="Resolve" onClick={() => setShowResolve(true)} size="large">
                    <StopIcon />           
                </IconButton>
            </span>
        </Tooltip>         
        <Tooltip title="Open CRM">    
            <span>
                <IconButton aria-label="Open CRM" onClick={handleOpenCrm} size="large">
                    <OpenInBrowserIcon />           
                </IconButton>
            </span>
        </Tooltip>   
        <TransferInteractionDialog showTransfer={showTransfer} setShowTransfer={setShowTransfer} />
        <ResolveInteractionDialog showResolve={showResolve} setShowResolve={setShowResolve} />
    </>;
}
export default AgentMediaChat;