import React, { useEffect, useRef } from 'react';
import { ArcElement, Chart, ChartItem, Legend, PieController } from "chart.js";
import { useSelector } from "react-redux";
import { AppState } from '../store';

Chart.register(Legend);
Chart.register(PieController);
Chart.register(ArcElement);

const CallSummaryChart = () => {
    const { callSummary } = useSelector((state: AppState) => state.report);
    let chartRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        if (chartRef.current)
        {
            const myChartRef = chartRef.current.getContext("2d");
            if (callSummary && callSummary.length > 0) {
                var labels = ['Completed', 'Abandoned', 'Incomplete'];
                var totalCompleted = callSummary.reduce((total, currentData) => total + currentData.completed, 0);
                var totalAbandoned = callSummary.reduce((total, currentData) => total + currentData.abandoned, 0);
                var totalIncomplete = callSummary.reduce((total, currentData) => total + currentData.incomplete, 0);
                var chartData = [totalCompleted, totalAbandoned, totalIncomplete];
                
                const existingChart = Chart.getChart(myChartRef as CanvasRenderingContext2D);
                if (existingChart) {
                    existingChart.destroy();
                }

                new Chart(myChartRef as ChartItem, {
                    type: "pie",
                    data: {
                        //Bring in data
                        labels: labels,
                        datasets: [
                            {
                                data: chartData,
                                backgroundColor: [
                                    'rgba(75, 192, 192, 0.8)',
                                    'rgba(255, 99, 132, 0.8)',
                                    'rgba(255, 205, 86, 0.8)'
                                ]
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                    }
                });
            }
        }
    });
    return (
        <>
            <canvas ref={chartRef} />
        </>
    );
}

export default CallSummaryChart;