import React, { useRef, useEffect } from 'react';
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { InteractionEvent } from '../store/currentInteraction/slice';

const useStyles = makeStyles((theme) => ({
    chatMessages: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    chatMessageContainer: {
        display: 'flex',
        width: '100%',        
    },
    selfChatMessageContainer: {
        justifyContent: 'flex-end'
    },
    otherChatMessageContainer: {
        justifyContent: 'flex-start'
    },    
    chatMessageContent: {
        borderRadius: "10px",
        margin: "5px",
        padding: "10px",
        display: "inline-block"
    },
    selfChatMessageContent: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    otherChatMessageContent: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText
    },
}));

const ChatMessagesContainer: React.FC<{ events: InteractionEvent[] }> = ({ events }) => {
    const classes = useStyles();
    let endRef = useRef<undefined | HTMLElement>(null);

    useEffect(() => {
        if (endRef.current) {            
            endRef.current.scrollIntoView();
        }
    }); 

    let items:JSX.Element[] = [];

    for (let current of events) {
        const itemClassName = current.agentId === null ? classes.otherChatMessageContainer : classes.selfChatMessageContainer;
        const contentClassName = current.agentId === null ? classes.otherChatMessageContent : classes.selfChatMessageContent;
        items.push(
            <div className={clsx(classes.chatMessageContainer, itemClassName)} key={current.id}>
                <div className={clsx(classes.chatMessageContent, contentClassName)}>{current.content}</div>
            </div>
        );
    }
    
    var boxProps = {
        ref: endRef
    };
    return (
        <Box className={classes.chatMessages}>
            {items}  
            <Box {...boxProps}>&nbsp;</Box>
        </Box>
    );
}

export default ChatMessagesContainer;