
const errorHandler = store => next => action => {
    try {
      return next(action);
    } catch (err) {
        console.error('Caught an exception while executing action!', err, action);
        throw err;
    }
  }
  
export default errorHandler;
