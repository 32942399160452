import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Agent, IAgentService } from '../../services/IAgentService';

export interface AgentState {
    agents: Agent[];
}

const getAgents = createAsyncThunk("agents/get",
    async (arg: string, thunkApi: any) => {
        const { agentService }: { agentService: IAgentService } = thunkApi.extra;
        return await agentService!.getAgents(arg);
    }
);

const addAgent = createAsyncThunk("agents/add",
    async (arg: Agent, thunkApi: any) => {
        const { agentService }: { agentService: IAgentService } = thunkApi.extra;
        var result = await agentService!.addAgent(arg);
        thunkApi.dispatch(getAgents(arg.tenantId));
        return result;
    }
);
const updateAgent = createAsyncThunk("agents/update",
    async (arg: Agent, thunkApi: any) => {
        const { agentService }: { agentService: IAgentService } = thunkApi.extra;
        var result = await agentService!.updateAgent(arg);
        thunkApi.dispatch(getAgents(arg.tenantId));
        return result;
    }
);


const agentSliceTemp = createSlice({
    name: 'agent',
    initialState: {
        agents: []
    } as AgentState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAgents.fulfilled, (state, action) => {
            state.agents = action.payload;
        });
    }
});

export const agentSlice = Object.assign(agentSliceTemp, { asyncActions: { getAgents, addAgent, updateAgent } });
export default agentSlice;