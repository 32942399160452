import BaseHttpService from './BaseHttpService'
import { ITokenProvider } from './IAuthenticationService';
import { Tenant } from './ITenantService';



export class TenantService extends BaseHttpService {
  constructor(apiUrl: string, tokenProvider: ITokenProvider) {
    super(apiUrl, tokenProvider);
  }

  async getTenant(id: string): Promise<Tenant> {
    const response = await fetch(`${this.apiUrl}/tenants/${id}`, {
      method: "GET",
      headers: await this.getAuthorizationHeader()
    });
    return (await response.json()) as Tenant;
  }

  async listTenants(): Promise<Tenant[]> {
    const response = await fetch(`${this.apiUrl}/tenants`, {
      method: "GET",
      headers: await this.getAuthorizationHeader()
    });
    return (await response.json()) as Tenant[];
  }

  async addTenant(tenant: Tenant): Promise<Tenant> {
    const response = await fetch(`${this.apiUrl}/tenants`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...(await this.getAuthorizationHeader()) },
      body: JSON.stringify({
        name: tenant.name,
        crmUrl: tenant.crmUrl
      })
    });
    return (await response.json()) as Tenant;
  }

  async updateTenant(tenant: Tenant): Promise<Tenant> {
    const response = await fetch(`${this.apiUrl}/tenants/${tenant.id}`, {
      method: "PUT",
      headers: { 'Content-Type': 'application/json', ...(await this.getAuthorizationHeader()) },
      body: JSON.stringify({
        name: tenant.name,
        crmUrl: tenant.crmUrl
      })
    });
    return (await response.json()) as Tenant;
  }
}