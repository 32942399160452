import { ITokenProvider } from "./IAuthenticationService";

export default class BaseHttpService
{
    protected tokenProvider: ITokenProvider;
    protected apiUrl: string;

    constructor(apiUrl: string, tokenProvider: ITokenProvider) {
        this.apiUrl = apiUrl;
        this.tokenProvider = tokenProvider;
    }

    protected async getAuthorizationHeader(): Promise<any> {
        const token = await this.tokenProvider.getAccessToken();
        return {
            'Authorization': "Bearer " +  token
        }
    }
}