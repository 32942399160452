import * as React from 'react';
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import { useRef, useEffect } from 'react';

const useStyles = makeStyles(() => ({
    container: {        
        backgroundColor: 'black',
        height: '100%',
        textAlign: 'center'
    },
    tile: {
        width: '100%',
        height: '100%'
    }
}));

const Stream: React.FC<{stream: any} & React.HTMLAttributes<HTMLDivElement>> = ({stream}) => {    
    const classes = useStyles();
    let streamRef = useRef(null);

    useEffect(() => {
        if (streamRef.current && stream !== null) {
            (streamRef!.current as any).srcObject = stream;
        }
    });

    return(        
        <Box className={classes.container}>                        
            <video className={classes.tile} ref={streamRef} autoPlay={true} playsInline={true} /> 
        </Box>
    );
}

export default Stream;