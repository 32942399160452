import * as React from 'react';
import {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { tenantSlice } from '../store/tenant/slice';
import { Tenant } from '../services/ITenantService';
import makeStyles from '@mui/styles/makeStyles';
import { 
  Link
} from 'react-router-dom';
import { AppState } from '../store';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    container:{

    },
    actionButtons:{
        padding: 0
    }
  });

export default function TenantAdminDashboard() {
    const classes = useStyles();
    const { isGlobalTenantAdmin } = useSelector((state:AppState) => state.currentUser);
    const { tenants } = useSelector((state:AppState) => state.tenant);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [tenantName, setTenantName] = useState("");
    const [tenantId, setTenantId] = useState("");
    const [crmUrl, setCrmUrl] = useState("");
    if (isGlobalTenantAdmin === false){
        return (<div id="page-wrapper" className="gray-bg"></div>);
    }

    const dispatch = useDispatch();

    const handleExecuteAdd = () => {        
        const tenant: Tenant = {
            name: tenantName,
            crmUrl: crmUrl
        };
        dispatch(tenantSlice.asyncActions.addTenant(tenant));
        setShowAdd(false);
    }

    const handleExecuteEdit = () => {      
        const tenant: Tenant = {
            id: tenantId,
            name: tenantName,
            crmUrl: crmUrl
        };
        dispatch(tenantSlice.asyncActions.updateTenant(tenant));
        setShowEdit(false);
    }

    const clearFields = () => {
        setTenantId("");
        setTenantName("");
        setCrmUrl("");
    }

    const loadEdit = (tenant) => {
        clearFields();
        setTenantId(tenant.id);
        setTenantName(tenant.name);
        setCrmUrl(tenant.crmUrl);
        setShowEdit(true);
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Crm Url</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tenants && Object.values(tenants).map((currentTenant, index) => 
                            <TableRow key={currentTenant.id} style={{paddingTop: 0, paddingBottom: 0}}>
                                <TableCell component="td" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="right">
                                    <Link to={"/admin/tenant/" + currentTenant.id + "/agent"}>{currentTenant.name}</Link>
                                </TableCell>
                                <TableCell align="right">
                                    {currentTenant.crmUrl}
                                </TableCell>
                                <TableCell align="right">         
                                    <Tooltip title="Edit">    
                                        <span>
                                            <IconButton
                                                aria-label="Edit"
                                                className={classes.actionButtons}
                                                onClick={() => loadEdit(currentTenant)}
                                                size="large">
                                                <EditIcon />           
                                            </IconButton>
                                        </span>
                                    </Tooltip> 
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={showAdd}>
                <DialogTitle>Add Tenant</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField margin="normal" label="Tenant Name" onChange={(event) => setTenantName(event.target.value)} value={tenantName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Crm Url" onChange={(event) => setCrmUrl(event.target.value)} value={crmUrl} fullWidth />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowAdd(false)} color="primary">Cancel</Button>
                    <Button onClick={handleExecuteAdd} color="primary">Add</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showEdit}>
                <DialogTitle>Edit Tenant</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField margin="normal" label="Tenant Name" onChange={(event) => setTenantName(event.target.value)} value={tenantName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Crm Url" onChange={(event) => setCrmUrl(event.target.value)} value={crmUrl} fullWidth />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setShowEdit(false); clearFields();}} color="primary">Cancel</Button>
                    <Button onClick={handleExecuteEdit} color="primary">Edit</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setShowAdd(true)}>Add Tenant</Button>
        </Container>
    );    
}