import * as React from 'react';
import { currentInteractionSlice } from '../store/currentInteraction/slice';
import { Interaction } from '../store/interaction/slice';
import { useSelector, useDispatch } from "react-redux";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import InputIcon from '@mui/icons-material/Input';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AppState } from '../store';

const PickupInteractionAction: React.FC<{ interaction: Interaction }> = ({ interaction }) => { 
    const dispatch = useDispatch();
    const { interactionId } = useSelector((state:AppState) => state.currentInteraction);

    const itemClicked = (itemInteractionId) => {
        if (interactionId != null){
            if (interactionId === itemInteractionId){
                return;
            }

            alert("you're handling a different ticket right now. Please end the current interaction before taking another");
            return;
        } else { 
            dispatch(currentInteractionSlice.asyncActions.assignInteraction(itemInteractionId));
        }
    }

    return <>
        <ListItemSecondaryAction>
            <Tooltip title="Pick up">
                <IconButton
                    edge="end"
                    aria-label="pick-up"
                    onClick={() => itemClicked(interaction.id)}
                    size="large">
                    <InputIcon />
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </>;
}

export default PickupInteractionAction;