import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IAgentInteractionSessionService, ListInteractionRequest, ListInteractionResponse } from '../../services/IAgentInteractionSessionService'
import { currentInteractionSlice } from "../currentInteraction/slice";

export interface InteractionState {
    interactions: Interaction[];
}
export interface Interaction {
    id: string;
    name: string | null;
    contactNumber: string | null;
    emailAddress: string | null;
    subject: string | null;
    message: string | null;
    otherInformation: any;
    categoryId: string;
    submittedTime: Date;
    tenantId: string;
    contactId: string;
}

const listInteractions = createAsyncThunk(
    "interactions/get",
    async (arg:ListInteractionRequest, thunkApi:any): Promise<ListInteractionResponse> => {
        const { interactionSessionService }: {interactionSessionService: IAgentInteractionSessionService } = thunkApi.extra;
        var result = await interactionSessionService!.list(arg);
        return result;
    });

const getInitialState = () => {
    return {
        interactions: [] as Interaction[]
    } as InteractionState;
}

const removeInteraction = (state: InteractionState, interactionId:string) => {    
    let i = 0;
    for(let currentInteraction of state.interactions){
        if (currentInteraction.id == interactionId) {
            state.interactions.splice(i, 1);
            break;
        }
        i++;
    }
}
const transferInteraction = (state: InteractionState, interactionId:string, newCategoryId:string) => {    
    for(let currentInteraction of state.interactions){
        if (currentInteraction.id == interactionId) {
            currentInteraction.categoryId = newCategoryId;
            break;
        }
    }
}
const interactionSliceTemp = createSlice({
    name: 'interaction',
    initialState: getInitialState(),
    reducers: {
        interactionCreated: (state, action) => {
            state.interactions.push(action.payload);
        },
        interactionTransferred: (state, action) => {
            transferInteraction(state, action.payload.interactionId, action.payload.categoryId);
        },
        interactionResolved: (state, action) => {
            removeInteraction(state, action.payload.interactionId);
        }
    },
    extraReducers: builder => {
        builder.addCase(listInteractions.fulfilled, (state, action) => {
            state.interactions = action.payload.interactions;
        });
        builder.addCase(currentInteractionSlice.asyncActions.resolveInteraction.fulfilled, (state,action) => {
            removeInteraction(state, action.payload.interactionId);
        });
        builder.addCase(currentInteractionSlice.asyncActions.transferInteraction.fulfilled, (state,action) => {
            transferInteraction(state, action.payload.interactionId, action.payload.newCategoryId);
        });
    }
});

export const interactionSlice = Object.assign(interactionSliceTemp, {
    asyncActions: {
        listInteractions
    }
});
export default interactionSlice;