import { HubConnection } from '@microsoft/signalr';
import { currentInteractionSlice } from '../store/currentInteraction/slice';
import BaseHttpService from './BaseHttpService';
import { ITokenProvider } from './IAuthenticationService';
import { 
    IInteractionSessionService, 
    AddChatMessageRequest,
    AddChatMessageResponse,
    JoinInteractionRequest, 
    JoinInteractionResponse, 
    CreateInteractionRequest, 
    CreateInteractionResponse
} from './IInteractionSessionService';
import { ISignalrConnectionProvider } from './ISignalrConnectionProvider';

export class SignalrInteractionSessionService extends BaseHttpService implements IInteractionSessionService {
    private connectionProvider: (ISignalrConnectionProvider | null) = null;
    protected dispatch: any;
    protected connection: HubConnection;

    constructor(apiUrl: string, tokenProvider: ITokenProvider, connectionProvider: ISignalrConnectionProvider) {
        super(apiUrl, tokenProvider);
        this.connectionProvider = connectionProvider;
        this.connection = this.connectionProvider!.getConnection();
        this.connection.on("NewInteractionEventCreated", (interactionId, event) => {
            this.dispatch(currentInteractionSlice.actions.interactionEventAdded(event));
        });
        this.connection.on("InteractionAssigned", (result) => {
            this.dispatch(currentInteractionSlice.actions.assigned(result))
        });
    }

    async initialize(dispatch: any): Promise<void> {
        this.dispatch = dispatch;
    }

    protected getConnection() {
        return this.connectionProvider ?.getConnection();
    }

    async join(request: JoinInteractionRequest): Promise<JoinInteractionResponse> {
        const result = await this.getConnection()!.invoke("JoinInteraction", request);

        return result as JoinInteractionResponse;
    }

    async addChatMessage(request: AddChatMessageRequest): Promise<AddChatMessageResponse> {
        const result = await this.getConnection()!.invoke("AddChatMessage", request);
        return result as AddChatMessageResponse;
    }

    async create(request: CreateInteractionRequest): Promise<CreateInteractionResponse> {
        const result = await this.getConnection()!.invoke("CreateInteraction", request);

        const response: CreateInteractionResponse = {
            id: result.id,
            categoryId: result.categoryId,
        };

        return response;
    }
}

