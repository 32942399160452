import { applicationSlice } from '../store/application/slice';

import { currentUserSlice } from '../store/currentUser/slice';
import { tenantSlice } from '../store/tenant/slice';
import { categorySlice } from '../store/category/slice';

const auth0 = store => next => action => {
    if (action.type === applicationSlice.actions.dashboardLoaded.type) {
        store.dispatch(currentUserSlice.asyncActions.login());
    } else if (action.type === currentUserSlice.asyncActions.login.fulfilled.type) {
        store.dispatch(currentUserSlice.asyncActions.getPermission());
    } else if (action.type === currentUserSlice.asyncActions.getPermission.fulfilled.type) {
        if (action.payload.isGlobalTenantAdmin) {
            store.dispatch(tenantSlice.asyncActions.listTenants());
            store.dispatch(categorySlice.asyncActions.getCategories(action.payload.tenantId));
        } else {            
            store.dispatch(tenantSlice.asyncActions.getTenant(action.payload.tenantId));
        }
    }

    return next(action)
}
  
export default auth0;
