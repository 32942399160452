import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { archivedSessionSlice } from "../store/archivedSession/slice";
import { GetArchivedSessionsRequest } from "../services/IAgentInteractionSessionService";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AppState } from '../store';

export default function InteractionSearchForm() {
    const { showSearch } = useSelector((state:AppState) => state.archivedSession);
    const { tenantId } = useSelector((state:AppState) => state.currentUser);
    const { categories } = useSelector((state:AppState) => state.category);
    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleStartDateChanged = (value) => {
        setStartDate(value);
    }
    const handleEndDateChanged = (value) => {
        setEndDate(value);
    }
    const dispatch = useDispatch();
    const handleSearch = (event) => {
        const query:GetArchivedSessionsRequest = {
            tenantId: tenantId!,
            startDate: startDate,
            endDate: endDate,
            name: name,
            status: ''
        };
        
        dispatch(archivedSessionSlice.asyncActions.getArchivedSessions(query));
    }
    
    const categoryOptions:JSX.Element[] = [];
    if (categories){
        categoryOptions.push(<option key="nullvaluestatus" value="">[Status]</option>)
        for (let i = 0; i < categories.length; ++i) {
            const value = categories[i];
            categoryOptions.push(<option key={value.id} value={value.id}>{value.name}</option> );
        }
    }

    const handleShowSearch = (event) => {
        dispatch(archivedSessionSlice.actions.showSearch(true));
    }

    const handleCloseSearch = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch(archivedSessionSlice.actions.showSearch(false));
        }
    }

    return <>            
        <IconButton color="inherit" onClick={handleShowSearch} size="large">
            <SearchIcon />
        </IconButton>
        <Dialog open={showSearch} onClose={handleCloseSearch} aria-labelledby="search-history-dialog">
            <DialogTitle id="form-dialog-title">Search</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter your search criteria.
                </DialogContentText>
                <div>
                    <TextField margin="normal" label="Name" onChange={(event) => setName(event.currentTarget.value)} value={name} fullWidth />
                </div>
                <div>
                    <DatePicker 
                        mask="____/__/__"
                        label="Start Date" 
                        inputFormat="yyyy/MM/dd"
                        value={startDate}
                        onChange={(handleStartDateChanged)}
                        renderInput={(props) => <TextField {...props} />} />
                </div>
                <div>
                    <DatePicker 
                        mask="____/__/__"
                        label="End Date" 
                        inputFormat="yyyy/MM/dd"
                        value={endDate}
                        onChange={handleEndDateChanged}
                        renderInput={(props) => <TextField {...props} />} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => handleCloseSearch(e, 'dummy')} color="primary">Cancel</Button>
                <Button onClick={handleSearch} color="primary">Search</Button>
            </DialogActions>
        </Dialog>
    </>;
}