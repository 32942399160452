import * as React from 'react';
import { archivedSessionSlice } from '../store/archivedSession/slice';
import { Interaction } from '../store/interaction/slice';
import { useDispatch, useSelector } from "react-redux";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AppState } from 'store';

const ViewArchivedChatPickupAction: React.FC<{ interaction: Interaction }> = ({ interaction }) => { 
    const dispatch = useDispatch();
    const { tenantId } = useSelector((state:AppState) => state.currentUser);
    const itemClicked = (itemInteractionId) => {
        dispatch(archivedSessionSlice.asyncActions.getArchivedSessionEvents({tenantId: tenantId!, interactionId: itemInteractionId}));
    }

    return <>
        <ListItemSecondaryAction>
            <Tooltip title="View Chat">
                <IconButton
                    edge="end"
                    aria-label="view-chat"
                    onClick={() => itemClicked(interaction.id)}
                    size="large">
                    <ChatIcon />
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </>;
}

export default ViewArchivedChatPickupAction;