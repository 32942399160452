import * as React from 'react';
import { Interaction } from '../store/interaction/slice';
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from "@mui/material/Box";
import { AppState } from '../store';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatDistanceToNow } from 'date-fns';


const useStyles = makeStyles(theme => ({
    listContainer: {
        display: "flex",
        flexDirection: "column",
    },
    titleContainer: {
        display: 'flex'
    },
    title: {
        textAlign: "center",
        flexGrow: 1,
    },
    list: {
      width: '100%',
      flexGrow:1,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
    extraInformation: {
        display: 'flex',
        flexDirection: 'row'
    },
    time: {
        flexGrow: 1,
        textAlign: 'right'
    }
  }));

export interface InteractionListProps {
    interactions: Interaction[],
    getSecondaryAction?: (interaction: Interaction) => React.ReactNode,
    getExpandedItem?: () => JSX.Element,
    title?: string,
    closeAction?: () => void,
}
const InteractionList: React.FC<InteractionListProps> = ({ interactions, getSecondaryAction, getExpandedItem, title, closeAction }) => { 
    const classes = useStyles();
    const { categories } = useSelector((state:AppState) => state.category);
    const { categoryIds } = useSelector((state:AppState) => state.currentUser);
    let items:JSX.Element[] = [];

    items.push(<Divider component="li" key={"divider-top"} />);
    for (const interaction of interactions) {
        let isAssignedCategory = false;
        
        for(let currentCategoryId of categoryIds){             
            if (currentCategoryId === interaction.categoryId){
                isAssignedCategory = true;
            }
        }
        if (isAssignedCategory === false) {
            continue;
        }

        let categoryName = "";
        for (let currentCategory of categories){        
            if (currentCategory.id == interaction.categoryId){
                categoryName = currentCategory.name;
            }
        }
        
        items.push(createInteractionListItem(interaction, categoryName));
        items.push(createDivider(interaction));
    }

    if (items.length == 0){
        var noSessionListItem = createNoSessionListItem();
        items.push(noSessionListItem);
    } else {        
        if (getExpandedItem) {
            items.push(getExpandedItem());
        }
    }
    return (
        <Box component="div" className={classes.listContainer}>
            <Box className={classes.titleContainer}>
                <Typography variant="button" display="block" className={classes.title}>{title}</Typography>
                {closeAction && <IconButton size="small" onClick={closeAction}><CloseIcon fontSize="inherit" /></IconButton>}
            </Box>
            <List className={classes.list}>{items}</List>
        </Box>
    );

    function createNoSessionListItem(): JSX.Element {
        return ( 
            <ListItem alignItems="flex-start"
                key={"lonely-interaction-list-item-id"}>
                <ListItemText
                    primary=""
                    secondary={<>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary">
                            No interaction yet :(
                        </Typography>
                    </>} />
            </ListItem>);
    }

    function createDivider(interaction: Interaction): JSX.Element {
        return (<Divider component="li" key={"divider" + interaction.id} />);
    }

    function createInteractionListItem(interaction: Interaction, categoryName: string): JSX.Element {
        return (<ListItem alignItems="flex-start"
            key={interaction.id}>
            <ListItemText
                primary={interaction.name ? interaction.name : "Anonymous"}
                secondary={<>
                    <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary">
                        {interaction.subject}
                    </Typography>
                    {' — ' + interaction.message}
                    <Box className={classes.extraInformation} component="span">
                        <Chip label={categoryName} size="small" component="span" />
                        <small className={classes.time}>{formatDistanceToNow(new Date(interaction.submittedTime))}</small>
                    </Box>
                </>} />
            {getSecondaryAction && getSecondaryAction(interaction)}
        </ListItem>);
    }
}

export default InteractionList;