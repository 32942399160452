import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { currentUserSlice } from "../store/currentUser/slice";
import sidebarSlice from "../store/sidebar/slice";
import { useTheme, Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Link as RouterLink } from 'react-router-dom';
import { AppState } from '../store';

const useStyles = (drawerWidth) => makeStyles<Theme, {}>(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}))();

export default function Sidebar(){
	const { drawerWidth } = useSelector((state: AppState) => state.ui);
	const {isSidebarExpanded} = useSelector((state: AppState) => state.sidebar);
	const { isAuthenticated, isGlobalTenantAdmin, isTenantAdmin, tenantId } = useSelector((state: AppState) => state.currentUser);
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles(drawerWidth);
	
	let adminLinks:JSX.Element[] = [];
	if (isGlobalTenantAdmin === true){
		adminLinks.push(
			<ListItem button component={RouterLink} to="/admin/" key="Tenants">
				<ListItemIcon>
					<SupervisedUserCircleIcon />
				</ListItemIcon>
				<ListItemText primary="Tenants" />
			</ListItem>	);
	}
	if (isTenantAdmin === true && tenantId !== null) {		
		adminLinks.push(
			<ListItem button component={RouterLink} to={"/admin/tenant/" + tenantId + "/agent"} key="Agents">
				<ListItemIcon>
					<GroupIcon />
				</ListItemIcon>
				<ListItemText primary="Agents" />
			</ListItem>	);
		adminLinks.push(
			<ListItem button component={RouterLink} to={"/admin/tenant/" + tenantId + "/report"} key="Reports">
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary="Reports" />
			</ListItem>	);
	}
	return <>
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={isSidebarExpanded}
            classes={{paper: classes.drawerPaper}}>
            <div className={classes.drawerHeader}>
            <IconButton
                onClick={() => dispatch(sidebarSlice.actions.sidebarClose())}
                size="large">
                {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
                ) : (
                <ChevronRightIcon />
                )}
            </IconButton>
            </div>
            <Divider />
            <List>
                {isAuthenticated && (
                    <ListItem button component={RouterLink} to={"/"} key="Dashboard">
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    )
                }					
                {isAuthenticated && adminLinks}
                {isAuthenticated && (
                    <ListItem button key="Log Out" onClick={() => dispatch(currentUserSlice.asyncActions.logout())}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log Out" />
                    </ListItem>			
                    )
                }
                {!isAuthenticated && (
                    <ListItem button key="Log In" onClick={() => window.location.href = "/"}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log In" />
                    </ListItem>			
                    )
                }
            </List>
        </Drawer>
    </>;
}