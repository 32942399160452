import BaseHttpService from './BaseHttpService'
import { ITokenProvider } from './IAuthenticationService';
import { IPermissionService, Permission } from './IPermissionService';

export class PermissionService extends BaseHttpService implements IPermissionService
{
    constructor(apiUrl: string, tokenProvider: ITokenProvider) {
        super(apiUrl, tokenProvider);
    }

    async getPermission(): Promise<Permission> {
        const response = await fetch(this.apiUrl + "/permissions", {
          method: "GET",
          headers: await this.getAuthorizationHeader()
        });
        return (await response.json()) as Permission;
    }
}

