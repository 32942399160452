import * as React from 'react';
import { useSelector, useDispatch } from "react-redux"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Stream from './Stream'
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import { AppState } from '../store';
import { IChatService, ChatStreams } from '../services/IChatService';
import currentInteractionSlice from '../store/currentInteraction/slice';

const useStyles = makeStyles((theme) => ({
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      maxHeight: `150px`,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    mediaChatContainer: {
        //width: `${mediaChatWidth}px`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    highlightedStream: {
        width: `100%`,
        height: `calc(100vh - 64px - 300px)`,
        maxHeight: `calc(100vh - 64px - 300px)`,
        backgroundColor: 'black'        
    },
    streamTile: {
        width: '100%',
        height: '150px',
        maxHeight: '150px'
    },
    actionBar: {
        margin: '0 auto',
        padding: '0px'
    }
}));

const StreamList: React.FC<{chatService: IChatService}> = ({chatService}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selfChatStreamState = useSelector((state: AppState) => state.currentInteraction.selfChatStreamState);
    const peersChatStreamState = useSelector((state: AppState) => state.currentInteraction.peersChatStreamState);
    
    const handleSetHighlightedStream = (value: {streamId?: string, peerId: string} | null) => {
        if (!(value?.streamId) || !(value?.peerId)) {
            dispatch(currentInteractionSlice.actions.highlightStreamUpdated(null));
        } else {
            dispatch(currentInteractionSlice.actions.highlightStreamUpdated({streamId: value.streamId, peerId: value.peerId}));
        }
    }
    let allStreams = [] as any[];
    if (selfChatStreamState !== null) {
        addStreams(classes, allStreams, handleSetHighlightedStream, chatService.getSelfStreamsInfo(), "self");
    }

    for(let peerId of Object.keys(peersChatStreamState)) {
        const streamInfo = chatService.getPeerStreamsInfo(peerId);
        addStreams(classes, allStreams, handleSetHighlightedStream, streamInfo, peerId); 
    }

    return (
        <ImageList className={classes.gridList} rowHeight={150} gap={0}>
            {allStreams}                 
        </ImageList>
    );
}

export default StreamList;

function addStreams(
    classes: any,
    allStreams: any[], 
    setHighlightedStream: React.Dispatch<React.SetStateAction<{streamId?: string, peerId: string} | null>>, 
    streams: ChatStreams, 
    peerId: string) {
    allStreams.push(
        <ImageListItem  className={classes.streamTile} onClick={() => setHighlightedStream({streamId: streams.audioVideoStream?.id, peerId: peerId})} key={peerId + "-audioVideo"}>
            <Box className={classes.streamTile}>
                <Stream stream={streams.audioVideoStream} />
            </Box>
        </ImageListItem>);
    if (streams.screenStream) {
        allStreams.push(
            <ImageListItem className={classes.streamTile} onClick={() => setHighlightedStream({streamId: streams.screenStream?.id, peerId: peerId})} key={peerId + "-screen"}>
                <Box className={classes.streamTile}>
                    <Stream stream={streams.screenStream} />
                </Box>
            </ImageListItem>);
    }
}
