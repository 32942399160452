import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IAuthenticationService } from '../../services/IAuthenticationService';
import { IPermissionService, Permission } from '../../services/IPermissionService';

export interface CurrentUserState {
    isAuthenticated: boolean;
    name: string | null;
    profilePictureLink: string | null;
    accessToken: string | null;
    isTenantAdmin: boolean;
    isGlobalTenantAdmin: boolean;
    tenantId: string | null;
    userId: string | null;
    categoryIds: string[];
}

export interface LoginActionResult {    
    name: string;
    profilePictureLink: string;
    userId: string;
}

const getPermission = createAsyncThunk(
    "authentication/permissions/get",
    async (arg:void, thunkApi:any): Promise<Permission> => {
        const { permissionService }: {permissionService: IPermissionService } = thunkApi.extra;
        return await permissionService.getPermission();
    });
const login = createAsyncThunk(
    "authentication/login", 
    async (arg:void, thunkApi: any): Promise<LoginActionResult> => {        
        const { authenticationService }: {authenticationService: IAuthenticationService } = thunkApi.extra;
        var result = await authenticationService.login();

        return result as LoginActionResult;
    });
const logout = createAsyncThunk(
    "authentication/logout", 
    async (arg:void, thunkApi: any): Promise<void> => {        
        const { authenticationService }: {authenticationService: IAuthenticationService } = thunkApi.extra;
        await authenticationService.logout();
    });

const currentUserSliceTemp = createSlice({
    name: 'authentication',
    initialState: { 
        isAuthenticated: false,
        name: null,
        profilePictureLink: null,
        accessToken: null,
        isTenantAdmin: false,
        isGlobalTenantAdmin: false,
        tenantId: null,
        userId: null,
        categoryIds: []
     } as CurrentUserState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getPermission.fulfilled, (state, action) => {
            const payload = action.payload;
            state.isTenantAdmin = payload.isTenantAdmin;
            state.isGlobalTenantAdmin = payload.isGlobalTenantAdmin;
            state.tenantId = payload.tenantId;
            state.categoryIds = payload.categoryIds;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const payload = action.payload;
            state.profilePictureLink = payload.profilePictureLink;
            //state.auth0UserId = auth0UserId,
            state.userId = payload.userId;
            state.name = payload.name;
            state.isAuthenticated =  true;
        });
    }
});

export const currentUserSlice = Object.assign(currentUserSliceTemp, { asyncActions: { getPermission, login, logout } });
export default currentUserSlice;