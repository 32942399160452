import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import { AppState } from '../store';
import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1
	}
}));
export default function ConnectionStatus() {
	const classes = useStyles();
    const application = useSelector((state:AppState) => state.application);

    const stateText = (application.isInitialized == false) ? "Initializing..." : ((application.isConnectedToServer == false) ? "Connecting..." : "Connected");
    const isConnected = application.isInitialized && application.isConnectedToServer;

	return (
    <>
        <Stack direction="row" spacing={2} className={classes.title}>
            {!isConnected && (<CircularProgress color="secondary" />) }
            <Typography variant="h6" noWrap className={classes.title}>
                {stateText}
            </Typography>
        </Stack>
        
    </>);
}