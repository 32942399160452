import { IAuthenticationService, ITokenProvider, UserInfo } from "./IAuthenticationService";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

export class AuthenticationService implements IAuthenticationService, ITokenProvider {

    private auth0Client: (Auth0Client | null) = null;
    private domain: string;
    private clientId: string;
    private redirectUri: string;
    private audience: string;
    private logoutUrl: string;
    private accessToken: string;

    constructor(domain: string,
        clientId: string,
        audience: string,
        logoutUrl: string) {
        this.domain = domain;
        this.clientId = clientId;
        this.redirectUri = window.location.origin;
        this.audience = audience;
        this.logoutUrl = logoutUrl;
    }

    async login(): Promise<UserInfo> {
        this.auth0Client = await createAuth0Client({
            domain: this.domain,
            client_id: this.clientId,
            redirect_uri: this.redirectUri,
            useRefreshTokens: true,
        });

        if (window.location.search.includes("code=")) {
            const url = window.location.href;;
            const redirectLoginResult = await this.auth0Client.handleRedirectCallback(url);
            const { appState } = redirectLoginResult;
            this.onRedirectCallback(appState);
        }

        const isAuthenticated = await this.auth0Client.isAuthenticated();
        if (!isAuthenticated) {
            this.auth0Client!.loginWithRedirect();
        }

        const user = await this.auth0Client.getUser();
        const token = await this.auth0Client.getTokenSilently({
            audience: this.audience
        });

        if (!user || !token){
            throw new Error("User or token is null.");
        }

        const userInfo: UserInfo = {
            userId: user!.sub!.split('|')[1],
            profilePictureLink: user!.picture!,
            name: user.nickName,
            accessToken: token
        };

        this.accessToken = token;

        return userInfo;
    }

    async logout(): Promise<void> {
        this.auth0Client!.logout({returnTo: this.logoutUrl});
    }

    canGetToken(): boolean {
        return true;
    }

    async getAccessToken(): Promise<string> {
        return Promise.resolve(this.accessToken);
    }
    // A function that routes the user to the right place
    // after login
    private onRedirectCallback = appState => {
        window.history.replaceState(
            {},
            document.title,
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    };


}