import * as React from 'react';
import { useSelector, useDispatch } from "react-redux"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import { ToggleStreamActionPayload, currentInteractionSlice } from "../store/currentInteraction/slice";
import { AppState } from '../store';
import { AsyncThunk } from '@reduxjs/toolkit';

function openFullscreen(id:string) {
    var elem = document.getElementById(id) as any;
    if (elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }
}

const useStyles = makeStyles((theme) => ({
    actionBar: {
        margin: '0 auto',
        padding: '0px',
        textAlign: 'center',
        width: '100%'
    }
}));

const ActionBar: React.FC<{extraTooltips?: React.ReactNode, openFullScreenElementId: string}> = ({extraTooltips, openFullScreenElementId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selfChatStreamState = useSelector((state: AppState) => state.currentInteraction.selfChatStreamState);
    const goFullScreen = () => openFullscreen(openFullScreenElementId);
        
    const toggleLocalStream = (isCalling:boolean, action:AsyncThunk<boolean, ToggleStreamActionPayload, {}>)=> {
        const payload: ToggleStreamActionPayload = {
            isEnabled: !isCalling
        };
        dispatch(action(payload));
    }

    const isAudioEnabled = selfChatStreamState?.isAudioEnabled ?? false;
    const isVideoEnabled = selfChatStreamState?.isVideoEnabled ?? false;
    const isScreenSharing = selfChatStreamState?.isScreenSharing ?? false;
    return (
        <Container className={classes.actionBar}>      
            <Tooltip title={selfChatStreamState?.isVideoEnabled ? "Stop Video": "Start Video"}>        
                <span>
                    <IconButton
                        aria-label="Toggle Video"
                        onClick={() => toggleLocalStream(isVideoEnabled, currentInteractionSlice.asyncActions.toggleVideo)}
                        size="large">
                        {!isVideoEnabled && (<VideocamOffIcon />)}
                        {isVideoEnabled && (<VideocamIcon />)}                            
                    </IconButton>
                </span>     
            </Tooltip>        
            <Tooltip title={isAudioEnabled ? "Mute Audio": "Start Audio"}>     
                <span>
                    <IconButton
                        onClick={() => toggleLocalStream(isAudioEnabled, currentInteractionSlice.asyncActions.toggleAudio)}
                        size="large">
                        {!isAudioEnabled && (<MicOffIcon />)}
                        {isAudioEnabled && (<MicIcon />)}                            
                    </IconButton>  
                </span>    
            </Tooltip>        
            <Tooltip title={isScreenSharing ? "Stop Screenshare": "Start Screenshare"}>    
                <span>
                    <IconButton
                        aria-label="Toggle ScreenShare"
                        onClick={() => toggleLocalStream(isScreenSharing, currentInteractionSlice.asyncActions.toggleScreen)}
                        size="large">
                        {!isScreenSharing && (<StopScreenShareIcon />)}
                        {isScreenSharing && (<ScreenShareIcon />)}                            
                    </IconButton>    
                </span>
            </Tooltip>
            <Tooltip title="Screenshot">        
                <span>
                    <IconButton size="large">
                        <AspectRatioIcon />                 
                    </IconButton>
                </span>     
            </Tooltip>  
            <Tooltip title="Fullscreen">        
                <span>
                    <IconButton onClick={goFullScreen} size="large">
                        <FullscreenIcon />                          
                    </IconButton>
                </span>     
            </Tooltip>
            {extraTooltips}  
        </Container>
    );
}

export default ActionBar;