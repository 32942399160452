import { IAuthenticationService } from "./services/IAuthenticationService";
import { IAgentService } from "./services/IAgentService";
import { ICategoryService } from "./services/ICategoryService";
import { IPermissionService } from "./services/IPermissionService";
import { IReportService } from "./services/IReportService";
import { ITenantService } from "./services/ITenantService";
import { ISignalrConnectionProvider } from "./services/ISignalrConnectionProvider";
import { IChatService } from "./services/IChatService";

import config from "./config";

import { AuthenticationService } from "./services/AuthenticationService";
import { AgentSignalrInteractionSessionService } from "./services/AgentSignalrInteractionSessionService";
import { AgentService } from "./services/AgentService";
import { CategoryService } from "./services/CategoryService";
import { PermissionService } from "./services/PermissionService";
import { ReportService } from "./services/ReportService";
import { TenantService } from "./services/TenantService";
import { SignalrConnectionProvider } from "./services/SignalrConnectionProvider";
import { SimplePeerChatService } from "./services/SimplePeerChatService";
import { IAgentInteractionSessionService } from "services/IAgentInteractionSessionService";

export interface ServiceContainer {
    authenticationService: IAuthenticationService;
    agentService: IAgentService;
    categoryService: ICategoryService;
    interactionSessionService: IAgentInteractionSessionService;
    permissionService: IPermissionService;
    reportService: IReportService;
    tenantService: ITenantService;
    signalrConnectionProvider: ISignalrConnectionProvider;
    chatService: IChatService;
}

const authenticationService = new AuthenticationService(
    config.auth.domain, 
    config.auth.clientId,
    config.auth.interactionServer.audience,
    config.auth.loggedOutReturnUrl);

const signalrConnectionProvider = new SignalrConnectionProvider(config.interactionServer.url, authenticationService);

const serviceContainer: ServiceContainer = {
    authenticationService: authenticationService,
    agentService: new AgentService(config.interactionServer.apiUrl, authenticationService),
    categoryService: new CategoryService(config.interactionServer.apiUrl, authenticationService),
    interactionSessionService: new AgentSignalrInteractionSessionService(config.interactionServer.apiUrl, authenticationService, signalrConnectionProvider),
    permissionService: new PermissionService(config.interactionServer.apiUrl, authenticationService),
    reportService: new ReportService(config.interactionServer.apiUrl, authenticationService),
    tenantService: new TenantService(config.interactionServer.apiUrl, authenticationService),
    signalrConnectionProvider: signalrConnectionProvider,  
    chatService: new SimplePeerChatService(signalrConnectionProvider, config.iceServers)
};

export default serviceContainer;