import React, { useEffect } from 'react';
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from "./PageHeader";
import Sidebar from "./Sidebar";
import { applicationSlice } from '../store/application/slice';

import TenantAdminDashboard from './TenantAdminDashboard';
import TenantAgentAdminDashboard from './TenantAgentAdminDashboard';
import TenantReportAdminDashboard from './TenantReportAdminDashboard';
import InteractionDashboard from './InteractionDashboard';
import { AppState } from '../store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import { IChatService } from '../services/IChatService';

const useStyles = (drawerWidth) => makeStyles<Theme, {}>(theme => ({
  root: {
    display: "flex",
    height: '100vh',
    paddingTop: '0',
    paddingBottom: '0',
    margin: '0'
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    height: 'calc(100vh - ' + 64 + 'px)'
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}))();


const App: React.FC<{ chatService: IChatService }> = ({ chatService }) => {
  const { drawerWidth } = useSelector((state: AppState) => state.ui);
  const { isSidebarExpanded } = useSelector((state: AppState) => state.sidebar);
  const classes = useStyles(drawerWidth);
  const dispatch = useDispatch();
  useEffect(() => { 
    const pathname = window.location.pathname;
    if (pathname !== "/loggedout") {
      dispatch(applicationSlice.actions.dashboardLoaded()); 
    }
  }, []);

  var layout = (component) => (<div className={classes.root}>
    <CssBaseline />
    <PageHeader />
    <Sidebar />
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: isSidebarExpanded
      })}>
      <div className={classes.drawerHeader} />
      {component}
    </main>
  </div>);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <Routes>
          <Route path="/loggedout" element={layout(
            <>
              <div>You have logged out. Please click <a href="/">here</a> to login again.</div>
            </>)} />
          <Route path="/admin/tenant/:tenantId/agent" element={layout(<TenantAgentAdminDashboard />)} />
          <Route path="/admin/tenant/:tenantId/report" element={layout(<TenantReportAdminDashboard />)} />
          <Route path="/admin/" element={layout(<TenantAdminDashboard />)} />
          <Route path="/" element={layout(<InteractionDashboard chatService={chatService} />)} />
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>);
}

export default App;