import * as React from 'react';
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import { IChatService } from '../services/IChatService';
import clsx from "clsx";
import MediaChat from './MediaChat';
import InteractionChat from './InteractionChat';

const useStyles = makeStyles((theme) => ({
    sessionContainer: {
        height: 'calc(100vh - ' + 64 + 'px)',   
        display: 'flex',
        flexDirection: 'row',
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        borderRightWidth: '1px',
        borderRightColor: theme.palette.divider,
        borderRightStyle: "solid",
        height: '100%'
    },
    mediaChatContainer: {
        minWidth: '400px',
        flexGrow: 2
    },
    textChatContainer: {        
        overflowY: 'auto',
        flexGrow: 1,
        display: 'flex'
    }
}));

const InteractionSession: React.FC<{chatService: IChatService, extraTooltips?: React.ReactNode}> = ({chatService, extraTooltips}) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.sessionContainer)}>
            <Box className={clsx(classes.mediaChatContainer)}>
                <MediaChat chatService={chatService} extraTooltips={extraTooltips} />
            </Box>
            <Box className={clsx(classes.textChatContainer)}>
                <InteractionChat />
            </Box>
        </Box>
    );
}

export default InteractionSession;