import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles';
import App from './components/App';
import { store } from "./store";

import serviceContainer from './serviceContainer';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme();

ReactDOM.render(
  <Provider store={store(serviceContainer)}>        
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App chatService={serviceContainer.chatService} />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('wrapper')
);