import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ICategoryService } from '../../services/ICategoryService';
import { Category } from "../../services/ICategoryService";

export interface CategoryState {
    categories: Category[];
}


const getCategories = createAsyncThunk("categories/get",
    async (arg: string, thunkApi: any) => {
        const { categoryService }: { categoryService: ICategoryService } = thunkApi.extra;
        return await categoryService!.getCategories(arg);
    }
);

const addCategory = createAsyncThunk("categories/add",
    async (arg: Category, thunkApi: any) => {
        const { categoryService }: { categoryService: ICategoryService } = thunkApi.extra;
        var result = await categoryService!.addCategory(arg);
        thunkApi.dispatch(getCategories(arg.tenantId));
        return result;
    }
);
const updateCategory = createAsyncThunk("categories/update",
    async (arg: Category, thunkApi: any) => {
        const { categoryService }: { categoryService: ICategoryService } = thunkApi.extra;
        var result = await categoryService!.updateCategory(arg);
        thunkApi.dispatch(getCategories(arg.tenantId));
        return result;
    }
);

const categorySliceTemp = createSlice({
    name: 'category',
    initialState: {
        categories: []
    } as CategoryState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
        });
    }
});

export const categorySlice = Object.assign(categorySliceTemp, { asyncActions: { getCategories, addCategory, updateCategory } });
export default categorySlice;