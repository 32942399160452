import React, { useEffect, useRef } from 'react';
import { Chart, ChartItem, CategoryScale, LinearScale, BarController, TimeScale, BarElement, Legend } from "chart.js";
import 'chartjs-adapter-date-fns';
import { useSelector } from "react-redux";
import { AppState } from '../store';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarController);
Chart.register(TimeScale);
Chart.register(BarElement);
Chart.register(Legend);
export default function CallDetailsChart() {
    const { callSummary } = useSelector((state: AppState) => state.report);
    let chartRef = useRef<HTMLCanvasElement>(null);
    var data = callSummary;
    useEffect(() => {
        if (chartRef.current)
        {
            const myChartRef = chartRef.current.getContext("2d");
            
            if (data && data.length > 0) {
                var labels = data.map((currentData) => new Date(currentData.start));
                var completes = data.map((currentData) => currentData.completed);
                var abandons = data.map((currentData) => currentData.abandoned);
                var incompletes = data.map((currentData) => currentData.incomplete);
                const existingChart = Chart.getChart(myChartRef as CanvasRenderingContext2D);
                if (existingChart) {
                    existingChart.destroy();
                }
                new Chart(myChartRef as ChartItem, {
                    type: "bar",
                    data: {
                        //Bring in data
                        labels: labels,
                        datasets: [
                            {
                                label: "Completed",
                                data: completes,
                                backgroundColor: 'rgba(75, 192, 192, 0.8)'
                            },
                            {
                                label: "Abandoned",
                                data: abandons,
                                backgroundColor: 'rgba(255, 99, 132, 0.8)'
                            },
                            {
                                label: "Incomplete",
                                data: incompletes,
                                backgroundColor: 'rgba(255, 205, 86, 0.8)'
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                                type: "time",
                                time: {
                                    unit: 'day'
                                }
                            },
                            y: {
                                stacked: true
                            }
                        }
                    }
                });
            }
        }
    });
    return (
        <>
            <canvas ref={chartRef} />
        </>
    );
}