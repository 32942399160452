import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Tenant, ITenantService } from '../../services/ITenantService';

export interface TenantState {
    tenants: { [key: string]: Tenant };
}

const getTenant = createAsyncThunk("tenants/get",
    async (arg:string, thunkApi: any) => {
        const { tenantService }: { tenantService: ITenantService } = thunkApi.extra;
        return await tenantService!.getTenant(arg);
    }
);

const listTenants = createAsyncThunk("tenants/list",
    async (arg:void, thunkApi: any) => {
        const { tenantService }: { tenantService: ITenantService } = thunkApi.extra;
        return await tenantService!.listTenants();
    }
);

const addTenant = createAsyncThunk("tenants/add",
    async (arg: Tenant, thunkApi: any) => {
        const { tenantService }: { tenantService: ITenantService } = thunkApi.extra;
        var result = await tenantService!.addTenant(arg);
        thunkApi.dispatch(listTenants());
        return result;
    }
);
const updateTenant = createAsyncThunk("tenants/update",
    async (arg: Tenant, thunkApi: any) => {
        const { tenantService }: { tenantService: ITenantService } = thunkApi.extra;
        var result = await tenantService!.updateTenant(arg);
        thunkApi.dispatch(listTenants());
        return result;
    }
);

const tenantSliceTemp = createSlice({
    name: 'tenant',
    initialState: {
        tenants: {}
    } as TenantState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(listTenants.fulfilled, (state, action) => {
            let map: { [key: string]: Tenant } = {};
            for (let current of action.payload) {
                map[current.id!] = current;
            }
            state.tenants = map;
        });
        builder.addCase(getTenant.fulfilled, (state, action) => {
            let map: { [key: string]: Tenant } = {...state.tenants};
            map[action.payload.id!] = action.payload;
            state.tenants = map;
        });
    }
});

export const tenantSlice = Object.assign(tenantSliceTemp, { asyncActions: { listTenants, getTenant, addTenant, updateTenant } });
export default tenantSlice;