import BaseHttpService from './BaseHttpService'
import { Category, ICategoryService } from './ICategoryService';
import { ITokenProvider } from './IAuthenticationService';

export class CategoryService extends BaseHttpService implements ICategoryService {
  constructor(apiUrl: string, tokenProvider: ITokenProvider) {
    super(apiUrl, tokenProvider);
  }

  async getCategories(tenantId: string): Promise<Category[]> {
    const response = await fetch(`${this.apiUrl}/tenants/${tenantId}/categories`, {
      method: "GET",
      headers: await this.getAuthorizationHeader()
    });
    return (await response.json()) as Category[];
  }

  async addCategory(category: Category): Promise<Category> {
    const response = await fetch(`${this.apiUrl}/tenants/${category.tenantId}/categories`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...(await this.getAuthorizationHeader()) },
      body: JSON.stringify({
        tenantId: category.tenantId,
        name: category.name
      })
    });
    return (await response.json()) as Category;
  }

  async updateCategory(category: Category): Promise<Category> {
    const response = await fetch(`${this.apiUrl}/tenants/${category.tenantId}/categories/${category.id}`, {
      method: "PUT",
      headers: { 'Content-Type': 'application/json', ...(await this.getAuthorizationHeader()) },
      body: JSON.stringify({
        tenantId: category.tenantId,
        name: category.name
      })
    });
    return (await response.json()) as Category;
  }
}