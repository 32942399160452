
import { ChatStreamState } from '../../services/IChatService';

export interface InteractionEvent {
    id: string;
    interactionId: string;
    agentId: string | null;
    contactId: string | null;
    type: string;
    content: string;
    dateTime: Date;
}

export interface InteractionState {
    events: InteractionEvent[];
    peersChatStreamState:  { [key: string]: ChatStreamState };
    selfChatStreamState: ChatStreamState | null;
    highlightStream: { streamId: string, peerId: string } | null;
    interactionId: string | null;
    status: InteractionStatus;
}

export enum InteractionStatus {
    Undefined = "Undefined",
    Submitted = "Submitted",
    InProgress = "InProgress",
    Transferred = "Transferred",
}

const getInitialState = () => {
    return {
        events: [] as InteractionEvent[],
        peersChatStreamState: { },
        selfChatStreamState: null,
        highlightStream: null,
        interactionId: null,
        status: InteractionStatus.Undefined,
    } as InteractionState;
}

const resetToInitialState = (state: InteractionState) => {
    const initialState = getInitialState();
    state.events = initialState.events;
    state.interactionId = initialState.interactionId;
    state.highlightStream = null;
    state.peersChatStreamState = initialState.peersChatStreamState;
    state.selfChatStreamState = initialState.selfChatStreamState;
    state.status = InteractionStatus.Undefined;
}

export {
    getInitialState, resetToInitialState
}