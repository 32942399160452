import { createSlice } from '@reduxjs/toolkit'

export interface SidebarState {
    isSidebarExpanded: boolean;
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: { isSidebarExpanded: false } as SidebarState,
    reducers: {
        toggleSidebar: (state) => {
            state.isSidebarExpanded = !state.isSidebarExpanded;
        },
        sidebarOpen: (state) => {
            state.isSidebarExpanded = true;
        },
        sidebarClose: (state) => {
            state.isSidebarExpanded = false;
        }
    }
});

export default sidebarSlice;