import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InteractionSession } from 'services/IInteractionSessionService';
import { InteractionEvent } from 'store/currentInteraction/base';
import { 
    IAgentInteractionSessionService,
    GetArchivedSessionsRequest,
    GetArchivedSessionsResponse,
    GetArchivedSessionEventsRequest,
    GetArchivedSessionEventsResponse,
} from '../../services/IAgentInteractionSessionService';

export interface HistoryState {
    showSearch: boolean,
    startDate: Date | null,
    endDate: Date | null,
    name: string | null,
    status: string | null,
    interactions: InteractionSession[],
    showResults: boolean,
    selectedInteractionId: string | null,
    selectedInteractionEvents: InteractionEvent[],
}

const getArchivedSessions = createAsyncThunk<GetArchivedSessionsResponse, GetArchivedSessionsRequest>("interactions/archived/get",
    async (arg: GetArchivedSessionsRequest, thunkApi: any) => {
        const { interactionSessionService }: { interactionSessionService: IAgentInteractionSessionService } = thunkApi.extra;
        return await interactionSessionService!.getArchived(arg);
    }
);

const getArchivedSessionEvents = createAsyncThunk<GetArchivedSessionEventsResponse, GetArchivedSessionEventsRequest>("interactions/archived/events/get",
    async (arg: GetArchivedSessionEventsRequest, thunkApi: any) => {
        const { interactionSessionService }: { interactionSessionService: IAgentInteractionSessionService } = thunkApi.extra;
        return await interactionSessionService!.getArchivedEvents(arg);
    }
);

const archivedSessionSliceTemp = createSlice({
    name: 'archivedSession',
    initialState: {
        showSearch: false,
        startDate: null,
        endDate: null,
        name: null,
        status: null,
        interactions: [],
        selectedInteractionId: null,
        showResults: false,
        selectedInteractionEvents: [] as InteractionEvent[],        
    } as HistoryState,
    reducers: {
        showSearch: (state, action) => {
            state.showSearch = action.payload;
            state.selectedInteractionId = null;
        },
        showResults: (state, action) => {
            state.showResults = action.payload;
            state.selectedInteractionId = null;            
        }
    },
    extraReducers: builder => {
        builder.addCase(getArchivedSessions.fulfilled, (state, action) => {
            const payload = action.payload;
            state.startDate = payload.startDate;
            state.endDate = payload.endDate;
            state.name = payload.name;
            state.status = payload.status;
            state.interactions = payload.interactions;
            state.showSearch = false;
            state.showResults = true;
            state.selectedInteractionId = null;
        });
        builder.addCase(getArchivedSessionEvents.fulfilled, (state, action) => {
            const payload = action.payload;
            state.selectedInteractionId = payload.interactionId;
            state.selectedInteractionEvents = payload.events;
        });
    }
});

export const archivedSessionSlice = Object.assign(archivedSessionSliceTemp, { asyncActions: { getArchivedSessions, getArchivedSessionEvents } });
export default archivedSessionSlice;