import { createSlice } from '@reduxjs/toolkit'

export interface DisplayState {
    drawerWidth: number;
    mediaChatWidth: number;
    mediaChatHeight: number;
}
export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        drawerWidth: 240,
        mediaChatWidth: 400,
        mediaChatHeight: 225
    } as DisplayState,
    reducers: {
    }
});

export default uiSlice;