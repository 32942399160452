import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    AddChatMessageRequest, 
    CreateInteractionRequest, 
    CreateInteractionResponse, 
    IInteractionSessionService, 
    JoinInteractionRequest
} from '../../services/IInteractionSessionService'
import { IChatService } from '../../services/IChatService';
import { getInitialState, InteractionStatus, InteractionEvent, resetToInitialState } from './base';
import { extensionExtraReducers, extensionAsyncActions } from './extension';

export * from './base';
export * from './extension';

export interface InitializeInteractionActionPayload {
    connectionInformation: any;
    interactionId: string;
}

export interface InitializeInteractionActionResult {
    isAudioEnabled: boolean;
    isVideoEnabled: boolean;
}

export interface JoinInteractionActionResult {
    interactionId: string;
    events: [],
    connectionInformation: any;
}

export interface AddChatMessageActionPayload {
    interactionId: string;
    message: string;
}

export interface AddChatMessageActionResult {
    interactionId: string;
    interactionEventId: string;
}

export interface ToggleStreamActionPayload {
    isEnabled: boolean;
}

export interface CreateInteractionActionPayload {
    name: string,
    emailAddress: string,
    contactNumber: string,
    subject: string,
    message: string,
    tenantId: string,
    otherInformation: any;
    categoryId: string;
}


const createInteraction = createAsyncThunk(
    "interactions/session/create",
    async (arg: CreateInteractionActionPayload, thunkApi: any) => {
        const { interactionSessionService }: { interactionSessionService: IInteractionSessionService } = thunkApi.extra;
         const createInteractionResponse = await interactionSessionService!.create(arg as CreateInteractionRequest);

        var result: CreateInteractionResponse = {
            id: createInteractionResponse.id,
            categoryId: createInteractionResponse.categoryId,
        };
        return result;
    });

const toggleAudio = createAsyncThunk<boolean, ToggleStreamActionPayload>(
    "interactions/session/localstream/audio/toggle",
    async (arg:ToggleStreamActionPayload, thunkApi: any): Promise<boolean> => {
        const { chatService }: { chatService: IChatService} = thunkApi.extra;
        return await chatService.toggleAudio(arg.isEnabled);
    });

const toggleVideo = createAsyncThunk<boolean, ToggleStreamActionPayload>(
    "interactions/session/localstream/video/toggle",
    async (arg:ToggleStreamActionPayload, thunkApi: any): Promise<boolean> => {
        const { chatService }: { chatService: IChatService} = thunkApi.extra;
        return await chatService.toggleVideo(arg.isEnabled);
    });

const toggleScreen = createAsyncThunk<boolean, ToggleStreamActionPayload>(
    "interactions/session/localstream/screen/toggle",
    async (arg:ToggleStreamActionPayload, thunkApi: any): Promise<boolean> => {
        const { chatService }: { chatService: IChatService} = thunkApi.extra;
        return await chatService.toggleScreen(arg.isEnabled);
    });

const joinInteraction = createAsyncThunk(
    "interactions/session/join",
    async (arg:string, thunkApi:any): Promise<JoinInteractionActionResult> => {
        const { interactionSessionService }: { interactionSessionService: IInteractionSessionService} = thunkApi.extra;
        const { currentUser } = thunkApi.getState();       
        const request: JoinInteractionRequest = {
            tenantId: currentUser.tenantId,
            interactionId: arg
        };
        const result = await interactionSessionService.join(request);
        return result as JoinInteractionActionResult;
    });

const initializeInteraction = createAsyncThunk(
    "interactions/session/initialize",
    async (arg:InitializeInteractionActionPayload, thunkApi:any): Promise<InitializeInteractionActionResult> => {        
        const { chatService }: { chatService: IChatService} = thunkApi.extra;
        const { currentUser } = thunkApi.getState();
        await chatService.initialize(thunkApi.dispatch, arg.connectionInformation, currentUser.tenantId, arg.interactionId);
        const selfStreams = chatService.getSelfStreamsInfo();
        let isVideoEnabled = false;
        let isAudioEnabled = false;
        if (selfStreams.audioVideoStream !== null) {
            const audioTracks = selfStreams.audioVideoStream.getAudioTracks();
            const videoTracks = selfStreams.audioVideoStream.getVideoTracks();
            if (audioTracks.length > 0) {
                isAudioEnabled = true;
            }
            if (videoTracks.length > 0) {
                isVideoEnabled = true;
            }
        }
        return {
            isAudioEnabled,
            isVideoEnabled
        };
    });
const startInteraction = createAsyncThunk(
    "interactions/session/start",
    async (arg:void, thunkApi:any): Promise<void> => {
        const { chatService }: { chatService: IChatService} = thunkApi.extra;
        await chatService.call();
    });

const addChatMessage = createAsyncThunk(
    "interactions/session/chat/add",
    async (arg:AddChatMessageActionPayload, thunkApi:any): Promise<AddChatMessageActionResult> => {        
        const { interactionSessionService }: { interactionSessionService: IInteractionSessionService} = thunkApi.extra;
        const { currentUser } = thunkApi.getState();
        const request: AddChatMessageRequest = {
            tenantId: currentUser.tenantId,
            interactionId: arg.interactionId,
            message: arg.message
        };
        await interactionSessionService.addChatMessage(request);
        return {            
            interactionId: arg.interactionId,
            interactionEventId: ""
        };
    });


const currentInteractionSliceTemp = createSlice({
    name: 'interactionSession',
    initialState: getInitialState(),
    reducers: {
        highlightStreamUpdated: (state, action) => {
            state.highlightStream = action.payload;
        },
        interactionEventAdded: (state, action: PayloadAction<InteractionEvent>) => {
            state.events.push(action.payload);
        },
        peerChatStreamStateChanged: (state, action) => {
            const newStreamState = {...state.peersChatStreamState};
            newStreamState[action.payload.peerId] = action.payload.chatStreamState;
            state.peersChatStreamState = newStreamState;
        },
        selfChatStreamStateChanged: (state, action) => {
            state.selfChatStreamState = action.payload.chatStreamState;
        },
        resolved: (state, action) => {
            if (state.interactionId && state.interactionId == action.payload.interactionId) {
                resetToInitialState(state);
            }
        },
        transferred: (state, action) => {
            if (state.interactionId && state.interactionId == action.payload.interactionId) {
                state.status = InteractionStatus.Transferred;
            }
        },
        assigned: (state, action) => {
            if (state.interactionId && state.interactionId == action.payload.interactionId) {
                state.status = InteractionStatus.InProgress;
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(joinInteraction.fulfilled, (state, action) => {
            state.events = action.payload.events;
            state.status = InteractionStatus.InProgress;
        });
                
        builder.addCase(createInteraction.fulfilled, (state, action) => {
            state.interactionId = action.payload.id;
            state.status = InteractionStatus.Submitted;
        });
        extensionExtraReducers(builder);
    }
});

let asyncActions = {
    joinInteraction,
    initializeInteraction,
    startInteraction,
    addChatMessage,
    toggleAudio,
    toggleVideo,
    toggleScreen,
    createInteraction
};
const extensionActions = extensionAsyncActions();
const asyncActionsExtended = {...asyncActions, ...extensionActions};

export const currentInteractionSlice = Object.assign(currentInteractionSliceTemp, {
    asyncActions: asyncActionsExtended
});
export default currentInteractionSlice;
