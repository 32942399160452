import React, { } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InteractionList from './InteractionList';
import InteractionSession from './InteractionSession';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { AppState } from '../store';
import { IChatService } from '../services/IChatService';
import ViewArchivedChatPickupAction from './ViewArchivedChatPickupAction';
import AgentMediaChat from './AgentMediaChat';
import { archivedSessionSlice } from '../store/archivedSession/slice';
import ChatMessagesContainer from './ChatMessagesContainer';
import ActiveInteractionList from './ActiveInteractionList';

const useStyles = makeStyles((theme) => ({
    interactionDashboard: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 'calc(100vh - ' + 64 + 'px)'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        borderRightWidth: '1px',
        borderRightColor: theme.palette.divider,
        borderRightStyle: "solid",
        overflowY: 'auto',
        height: '100%'
    },
    interactionListContainer: {
        width: '400px',
    },
    searchResultsContainer: {
        width: '400px',
    },
    interactionContainer: {
        flexGrow: 1,
    }
}));
const InteractionDashboard: React.FC<{ chatService: IChatService }> = ({ chatService }) => {
    const classes = useStyles();
    const interactionId = useSelector((state: AppState) => state.currentInteraction.interactionId);
    const { 
        interactions:archivedInteractions, 
        showResults:showArchived, 
        selectedInteractionEvents:archivedEvents, 
        selectedInteractionId:selectedArchivedInteractionId 
    } = useSelector((state: AppState) => state.archivedSession);

    const dispatch = useDispatch();
    const hasInteraction = interactionId !== null;
    const showArchivedChat = selectedArchivedInteractionId !== null;
    const hasArchivedChat = archivedEvents !== null && archivedEvents.length > 0;
    return (
        <>
            <Box className={classes.interactionDashboard}>
                {!hasInteraction && !showArchived
                &&  <Slide direction="right" in={!hasInteraction} mountOnEnter unmountOnExit>
                        <Box className={clsx(classes.interactionListContainer, classes.container)}>
                            <ActiveInteractionList />
                        </Box>
                    </Slide>
                }
                {showArchived 
                &&  (<Slide direction="right" in={showArchived} mountOnEnter unmountOnExit>
                        <Box className={clsx(classes.searchResultsContainer, classes.container)}>
                            <InteractionList 
                                title="Historical"
                                interactions={archivedInteractions}
                                getSecondaryAction={(interaction) => (<ViewArchivedChatPickupAction interaction={interaction} />)}
                                closeAction={() => dispatch(archivedSessionSlice.actions.showResults(false))}
                            />
                        </Box>
                    </Slide>)
                }
                {showArchived && showArchivedChat && hasArchivedChat &&(
                    <Box className={classes.interactionContainer}>
                        <ChatMessagesContainer events={archivedEvents} />
                    </Box>
                )
                }{showArchived && showArchivedChat && !hasArchivedChat &&(
                    <Box className={classes.interactionContainer}>
                        No chat messages
                    </Box>
                )
                }
                <Slide direction="left" in={hasInteraction} mountOnEnter unmountOnExit>
                    <Box className={clsx(classes.interactionContainer, classes.container)}>
                        {hasInteraction && (<InteractionSession chatService={chatService} extraTooltips={(<AgentMediaChat />)} />)}
                    </Box>
                </Slide>
            </Box>
        </>
    );
}

export default InteractionDashboard;