import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import logger from "redux-logger";

import interactionServer from '../middlewares/interactionserver';
import errorHandler from '../middlewares/errorHandler';
import authenticationMiddleware from '../middlewares/authentication';

import { combineReducers } from "redux";
import applicationSlice from './application/slice';
import sidebarSlice from "./sidebar/slice";
import tenantSlice from "./tenant/slice";
import agentSlice from "./agent/slice";
import categorySlice from "./category/slice";
import uiSlice from "./ui/slice";
import currentUserSlice from './currentUser/slice';
import reportSlice from './report/slice';
import archivedSessionSlice from './archivedSession/slice';
import interactionSlice from './interaction/slice';
import currentInteractionSlice from './currentInteraction/slice';

import serviceContainer from '../serviceContainer';
import { ServiceContainer } from '../serviceContainer';

const rootReducer = combineReducers({ 
    application: applicationSlice.reducer, 
    currentUser: currentUserSlice.reducer, 
    sidebar: sidebarSlice.reducer,
    interaction: interactionSlice.reducer,
    currentInteraction: currentInteractionSlice.reducer,
    category : categorySlice.reducer, 
    tenant: tenantSlice.reducer,
    agent: agentSlice.reducer,
    report: reportSlice.reducer,
    archivedSession: archivedSessionSlice.reducer,
    ui: uiSlice.reducer
});


export const store = (serviceContainer: ServiceContainer) => configureStore({
    reducer: rootReducer,
    middleware: [ 
        ...getDefaultMiddleware({
            thunk: {
                extraArgument: serviceContainer
            } 
        }), 
        authenticationMiddleware, 
        interactionServer, 
        logger, 
        errorHandler 
    ] as const
})

const temp = store(serviceContainer);
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof temp.dispatch;