import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ReportQuery, IReportService, TotalCallsSummary, AgentSummary } from '../../services/IReportService';

export interface ReportState {
    startDate: Date | null,
    endDate: Date | null,
    callSummary: TotalCallsSummary[],
    agentSummary: AgentSummary[]
}

const getReport = createAsyncThunk("reports/get",
    async (arg: ReportQuery, thunkApi: any) => {
        const { reportService }: { reportService: IReportService } = thunkApi.extra;
        return await reportService!.getReport(arg);
    }
);

const reportSliceTemp = createSlice({
    name: 'tenant',
    initialState: {
        startDate: null,
        endDate: null,
        callSummary: [],
        agentSummary: []
    } as ReportState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getReport.fulfilled, (state, action) => {
            const payload = action.payload;
            state.startDate = payload.startDate;
            state.endDate = payload.endDate;
            state.callSummary = payload.callSummary;
            state.agentSummary = payload.agentSummary;
        });
    }
});

export const reportSlice = Object.assign(reportSliceTemp, { asyncActions: { getReport } });
export default reportSlice;