import { interactionSlice } from '../store/interaction/slice';
import { SignalrInteractionSessionService } from './SignalrInteractionSessionService';
import { ISignalrConnectionProvider } from './ISignalrConnectionProvider';
import { ITokenProvider } from './IAuthenticationService';
import { GetArchivedSessionEventsRequest, GetArchivedSessionEventsResponse, GetArchivedSessionsRequest, GetArchivedSessionsResponse, IAgentInteractionSessionService, ListInteractionRequest, ListInteractionResponse, ResolveInteractionRequest, ResolveInteractionResponse, TransferInteractionRequest, TransferInteractionResponse } from './IAgentInteractionSessionService';
import { formatISO } from 'date-fns';

export class AgentSignalrInteractionSessionService extends SignalrInteractionSessionService implements IAgentInteractionSessionService {
    constructor(apiUrl: string, tokenProvider: ITokenProvider, connectionProvider: ISignalrConnectionProvider) {
        super(apiUrl, tokenProvider, connectionProvider);
        
        this.connection.on("NewInteractionCreated", (interaction) => {
            this.dispatch(interactionSlice.actions.interactionCreated(interaction));
        });
        this.connection.on("InteractionTransferred", (interactionId, newCategoryId) => {
            this.dispatch(interactionSlice.actions.interactionTransferred({ interactionId, categoryId: newCategoryId }));
        });
        this.connection.on("InteractionResolved", (interactionId, resolution) => {
            this.dispatch(interactionSlice.actions.interactionResolved({ interactionId, resolution }));
        });
    }
    async list(request: ListInteractionRequest): Promise<ListInteractionResponse> {
        const url = `${this.apiUrl}/tenants/${request.tenantId}/interactions`;
        const response = await fetch(url, {
          method: "GET",
          headers: await this.getAuthorizationHeader()
        });

        return (await response.json()) as ListInteractionResponse;
    }

    async assignToSelf(id: string): Promise<void> {
        await this.getConnection()!.invoke("AssignInteractionToSelf", id);
    }

    async resolve(request: ResolveInteractionRequest): Promise<ResolveInteractionResponse> {
        return (await this.getConnection()!.invoke("ResolveInteraction", request)) as ResolveInteractionResponse;
    }

    async transfer(request: TransferInteractionRequest): Promise<TransferInteractionResponse> {
        return (await this.getConnection()!.invoke("TransferInteraction", request)) as TransferInteractionResponse;
    }

    async getArchived(request: GetArchivedSessionsRequest): Promise<GetArchivedSessionsResponse> {
        const startDateString = encodeURIComponent(formatISO(request.startDate));
        const endDateString = encodeURIComponent(formatISO(request.endDate));
        const nameString = encodeURIComponent(request.name);
        const url = `${this.apiUrl}/tenants/${request.tenantId}/interactions/archived?startDate=${startDateString}&endDate=${endDateString}&name=${nameString}`;
        const response = await fetch(url, {
          method: "GET",
          headers: await this.getAuthorizationHeader()
        });

        if (!response.ok) {
            throw new Error(`Failed to get archived sessions. Status code is '${response.status}' status text '${response.statusText}'.`);
        }
        return (await response.json()) as GetArchivedSessionsResponse;
      }

    async getArchivedEvents(request: GetArchivedSessionEventsRequest): Promise<GetArchivedSessionEventsResponse> {
        const url = `${this.apiUrl}/tenants/${request.tenantId}/interactions/archived/${request.interactionId}/events`;
        const response = await fetch(url, {
        method: "GET",
        headers: await this.getAuthorizationHeader()
        });
        return {
            interactionId: request.interactionId,
            events: (await response.json())
        };
    }
}

