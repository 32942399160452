import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { agentSlice } from '../store/agent/slice';
import makeStyles from '@mui/styles/makeStyles';
import { AppState } from '../store';
import { Agent } from '../services/IAgentService';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    container: {

    },
    actionButtons:{
        padding: 0
    }
  });

type UrlParameters ={
    tenantId: string | undefined;
}
export default function TenantAgentAdminDashboard() {
    const classes = useStyles();
    const { isTenantAdmin, isGlobalTenantAdmin } = useSelector((state:AppState) => state.currentUser);
    const { categories } = useSelector((state:AppState) => state.category);
    const { agents } = useSelector((state:AppState) => state.agent);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [nickName, setNickName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [agentId, setAgentId] = useState("");

    var categoriesDictionary:any[] = [];

    const getInitialCategoryIds = () => {
        let selectedCategoryIds = {};
        for(let currentCategory of categories){
            categoriesDictionary[currentCategory.id!] = currentCategory;
            selectedCategoryIds[currentCategory.id!] = false;
        }
        return selectedCategoryIds;
    }

    const [categoryIds, setCategoryIds] = React.useState(getInitialCategoryIds());

    let { tenantId } = useParams<UrlParameters>();

    if (isTenantAdmin === false && isGlobalTenantAdmin === false){
        return (<div id="page-wrapper" className="gray-bg">UNAUTHORIZED</div>);
    }

    const dispatch = useDispatch();
    useEffect(() => { dispatch(agentSlice.asyncActions.getAgents(tenantId!)) }, []);

    const handleExecuteAdd = () => {    
        let newAgentCategoryIds:any[] = [];
        for(var currentCategoryId in categoryIds) {
            if(categoryIds[currentCategoryId]) {
                newAgentCategoryIds.push(currentCategoryId);
            }
        }    
        const agent: Agent = {
            tenantId: tenantId!,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
            nickName: nickName,
            emailAddress: emailAddress,
            password: password,
            categoryIds: newAgentCategoryIds,
            isEnabled: true
        };
        dispatch(agentSlice.asyncActions.addAgent(agent));
        setShowAdd(false);
        clearFields();
    }

    const clearFields = () => {
        setAgentId("");
        setFirstName("");
        setLastName("");
        setFullName("");
        setNickName("");
        setEmailAddress("");
        setPassword("");
        setCategoryIds(getInitialCategoryIds());
    }

    const loadEdit = (agent) => {
        clearFields();
        setAgentId(agent.id);
        setFirstName(agent.firstName);
        setLastName(agent.lastName);
        setFullName(agent.fullName);
        setNickName(agent.nickName);
        setEmailAddress(agent.emailAddress);

        var selectedCategoryIdsEdit = getInitialCategoryIds();
        for(var currentCategoryId of agent.categoryIds){
            selectedCategoryIdsEdit[currentCategoryId] = true;
        }
        setCategoryIds(selectedCategoryIdsEdit);
        setShowEdit(true);
    }

    const handleExecuteEdit = () => {    
        var newAgentCategoryIds:any[] = [];
        for(var currentCategoryId in categoryIds) {
            if(categoryIds[currentCategoryId]) {
                newAgentCategoryIds.push(currentCategoryId);
            }
        }
        const agent: Agent = {
            id: agentId,
            tenantId: tenantId!,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
            nickName: nickName,
            emailAddress: emailAddress,
            password: password,
            categoryIds: newAgentCategoryIds,
            isEnabled: true
        };
        dispatch(agentSlice.asyncActions.updateAgent(agent));
        setShowEdit(false);
        clearFields();
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">#</TableCell>
                            <TableCell align="right">First Name</TableCell>
                            <TableCell align="right">Last Name</TableCell>
                            <TableCell align="right">Full Name</TableCell>
                            <TableCell align="right">Nick Name</TableCell>
                            <TableCell align="right">Email Address</TableCell>
                            <TableCell align="right">Enabled</TableCell>
                            <TableCell align="right">Assigned</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agents && agents.map((agent, index) => (
                            <TableRow key={agent.id}>
                                <TableCell align="right">{index + 1}</TableCell>
                                <TableCell align="right">{agent.firstName}</TableCell>
                                <TableCell align="right">{agent.lastName}</TableCell>
                                <TableCell align="right">{agent.fullName}</TableCell>
                                <TableCell align="right">{agent.nickName}</TableCell>
                                <TableCell align="right">{agent.emailAddress}</TableCell>
                                <TableCell align="right">{agent.isEnabled}</TableCell>
                                <TableCell align="right">{agent.categoryIds.map((currentValue, index) => categoriesDictionary[currentValue].name + (index == agent.categoryIds.length - 1 ? "" : ", "))}</TableCell>
                                <TableCell align="right">         
                                    <Tooltip title="Edit">    
                                        <span>
                                            <IconButton
                                                aria-label="Edit"
                                                className={classes.actionButtons}
                                                onClick={() => loadEdit(agent)}
                                                size="large">
                                                <EditIcon />           
                                            </IconButton>
                                        </span>
                                    </Tooltip> 
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={showAdd}>
                <DialogTitle>Add Agent</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField margin="normal" label="First Name" onChange={(event) => setFirstName(event.target.value)} value={firstName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Last Name" onChange={(event) => setLastName(event.target.value)} value={lastName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Full Name" onChange={(event) => setFullName(event.target.value)} value={fullName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Nick Name" onChange={(event) => setNickName(event.target.value)} value={nickName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Email Address" onChange={(event) => setEmailAddress(event.target.value)} value={emailAddress} fullWidth />
                    </div>
                    <div>
                        <TextField type="password" margin="normal" label="Password" onChange={(event) => setPassword(event.target.value)} value={password} fullWidth />
                    </div>
                    <div>
                        {categories && categories.map((currentValue) =>
                            <FormControlLabel
                            key={currentValue.id}
                            control={
                              <Checkbox
                                key={currentValue.id}
                                checked={categoryIds[currentValue.id!]}
                                onChange={(event) => setCategoryIds({ ...categoryIds, [currentValue.id!]: event.target.checked })}
                                value={currentValue.id}
                              />
                            }
                            label={currentValue.name}
                          />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowAdd(false)} color="primary">Cancel</Button>
                    <Button onClick={handleExecuteAdd} color="primary">Add</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showEdit}>
                <DialogTitle>Edit Agent</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField margin="normal" label="First Name" onChange={(event) => setFirstName(event.target.value)} value={firstName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Last Name" onChange={(event) => setLastName(event.target.value)} value={lastName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Full Name" onChange={(event) => setFullName(event.target.value)} value={fullName} fullWidth />
                    </div>
                    <div>
                        <TextField margin="normal" label="Nick Name" onChange={(event) => setNickName(event.target.value)} value={nickName} fullWidth />
                    </div>
                    <div>
                        {categories && categories.map((currentValue) =>
                            <FormControlLabel
                            key={currentValue.id}
                            control={
                              <Checkbox
                                key={currentValue.id}
                                checked={categoryIds[currentValue.id!]}
                                onChange={(event) => setCategoryIds({ ...categoryIds, [currentValue.id!]: event.target.checked })}
                                value={currentValue.id}
                              />
                            }
                            label={currentValue.name}
                          />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setShowEdit(false); clearFields();}} color="primary">Cancel</Button>
                    <Button onClick={handleExecuteEdit} color="primary">Edit</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setShowAdd(true)}>Add Agent</Button>
        </Container>
    );    
}
