import * as React from 'react';
import InteractionList from './InteractionList';
import PickupInteractionAction from './PickupInteractionAction';
import { useSelector } from "react-redux";
import { AppState } from '../store';

const ActiveInteractionList = () => {
    const activeInteractions = useSelector((state: AppState) => state.interaction.interactions);
    return (
        <InteractionList 
            title="Current"
            interactions={activeInteractions} 
            getSecondaryAction={(interaction) => (<PickupInteractionAction interaction={interaction} />)} />
    );
}

export default ActiveInteractionList;