import * as React from 'react';
import { useSelector, useDispatch } from "react-redux"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { currentInteractionSlice, ResolveInteractionActionPayload } from "../store/currentInteraction/slice";
import { AppState } from '../store';
import Select from '@mui/material/Select';

enum CompletionStatus {
    Completed = "Completed",
    Incomplete = "Incomplete",
    Abandoned = "Abandoned",
}
export default function ResolveInteractionDialog({showResolve, setShowResolve}) {
    const dispatch = useDispatch();
    const [resolution, setResolution] = useState("");
    const [completionStatus, setCompletionStatus] = useState(CompletionStatus.Completed);
    const { tenantId } = useSelector((state:AppState) => state.currentUser);
    const { interactionId } = useSelector((state:AppState) => state.currentInteraction);

    const handleExecuteResolve = () => {        
        const payload:ResolveInteractionActionPayload = {
            tenantId: tenantId!,
            interactionId: interactionId!,
            resolution, 
            status: completionStatus,
        };
        dispatch(currentInteractionSlice.asyncActions.resolveInteraction(payload));
        setShowResolve(false);
    }

    const handleClose = (_, reason) => {
        if (reason !== 'backdropClick') {
            setShowResolve(false);
        }
    }

    const handleCompletionStatusChanged = (event) => {
        const status = event.target.value as CompletionStatus;
        setCompletionStatus(status);
    }

    let options = new Array<JSX.Element>();
    for (let currentStatus in CompletionStatus) {
        const status = CompletionStatus[currentStatus] as CompletionStatus;
        options.push(<MenuItem value={status} key={`${currentStatus}-value`}>{currentStatus}</MenuItem>)
    }

    return(            
        <Dialog open={showResolve} onClose={handleClose}>
            <DialogTitle>Resolve Interaction</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Resolve Interaction
                </DialogContentText>
                <div>
                <FormControl>
                    <InputLabel id="completion-status-label">Status</InputLabel>
                    <Select
                        labelId="completion-status-label"
                        value={completionStatus}
                        onChange={handleCompletionStatusChanged}>
                        {options}
                    </Select>
                </FormControl>
                   
                </div>
                <div>
                    <TextField margin="normal" 
                                label="Resolution" 
                                onChange={(event) => setResolution(event.target.value)} 
                                value={resolution} 
                                fullWidth />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowResolve(false)} color="primary">Cancel</Button>
                <Button onClick={handleExecuteResolve} color="primary">Resolve</Button>
            </DialogActions>
        </Dialog>
    );
}