import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import CallSummaryChart from './CallSummaryChart';
import CallDetailsChart from './CallDetailsChart';
import CallSummaryTable from './CallSummaryTable';
import AgentSummaryTable from './AgentSummaryTable';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import reportSlice from '../store/report/slice';
import { useParams } from "react-router";
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 320,
    },
  }));

type UrlParameters = {
    tenantId: string | undefined;
}
export default function TenantReportAdminDashboard() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    let { tenantId } = useParams<UrlParameters>();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const dispatch = useDispatch();
    const handleSearch = (event) => {
        const query = {
            tenantId: tenantId!,
            startDate: startDate,
            endDate: endDate
        };
        dispatch(reportSlice.asyncActions.getReport(query))
    }
    const handleStartDateChanged = (value) => {
        setStartDate(value);
    }
    const handleEndDateChanged = (value) => {
        setEndDate(value);
    }
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Box>                        
                <DatePicker 
                        label="Start Date" 
                        inputFormat="yyyy/MM/dd"
                        value={startDate}
                        onChange={handleStartDateChanged}
                        renderInput={(props) => <TextField {...props} />} />
                <DatePicker 
                    label="End Date" 
                    inputFormat="yyyy/MM/dd"
                    value={endDate}
                    onChange={handleEndDateChanged}
                    renderInput={(props) => <TextField {...props} />} />
                <Button onClick={handleSearch} color="primary">Search</Button>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper className={fixedHeightPaper}>
                        <CallSummaryChart />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper className={fixedHeightPaper}>
                        <CallDetailsChart />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h3>Call Summary</h3>
                        <CallSummaryTable />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h3>Agent Summary</h3>
                        <AgentSummaryTable />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}