import * as React from 'react';
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import { IChatService } from '../services/IChatService';
import ActionBar from './ActionBar';
import StreamList from './StreamList';
import HighlightStream from './HighlightStream';

const useStyles = makeStyles((theme) => ({
    mediaChatContainer: {
        //width: `${mediaChatWidth}px`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
}));

const MediaChat: React.FC<{chatService: IChatService, extraTooltips?: React.ReactNode}> = ({chatService, extraTooltips}) => {
    const classes = useStyles();
    return (
        <Box id="media-chat-container" className={classes.mediaChatContainer}>
            <HighlightStream chatService={chatService} />
            <StreamList chatService={chatService} />
            <ActionBar openFullScreenElementId={'media-chat-container'} extraTooltips={extraTooltips} />
        </Box>
    );
}

export default MediaChat;