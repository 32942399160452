import React from 'react';
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AppState } from '../store';

export default function CallSummaryTable() {
    const { callSummary } = useSelector((state: AppState) => state.report);
    
    let rows:JSX.Element[] = [];
    let i = 0;
    if (callSummary && callSummary.length > 0) {
        const grandTotal = callSummary.reduce((total, current) => total + current.completed + current.abandoned + current.incomplete, 0);
        const grandTotalDenominator = grandTotal == 0 ? 1: grandTotal / 100;
        for(let current of callSummary){
            let total = current.completed + current.abandoned + current.incomplete;
            let totalDenominator = total == 0 ? 1: total / 100;
            rows.push(<TableRow key={`call-summary-row${i++}`}>
                <TableCell>{current.start}</TableCell>
                <TableCell>{current.completed}</TableCell>
                <TableCell>{current.abandoned}</TableCell>
                <TableCell>{current.incomplete}</TableCell>
                <TableCell>{total}</TableCell>
                <TableCell>{current.completed / totalDenominator}</TableCell>
                <TableCell>{current.abandoned / totalDenominator}</TableCell>
                <TableCell>{current.incomplete / totalDenominator}</TableCell>
                <TableCell>{total / grandTotalDenominator}</TableCell>
            </TableRow>)
        }
    }
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Completed</TableCell>
                        <TableCell>Abandoned</TableCell>
                        <TableCell>Incomplete</TableCell>
                        <TableCell>Total Calls</TableCell>
                        <TableCell>Completed %</TableCell>
                        <TableCell>Abandoned %</TableCell>
                        <TableCell>Incomplete %</TableCell>
                        <TableCell>Volume %</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </>
    );
}