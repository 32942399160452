import { applicationSlice } from '../store/application/slice';
import { interactionSlice } from '../store/interaction/slice';
import currentInteractionSlice, { InitializeInteractionActionPayload } from '../store/currentInteraction/slice';
import currentUserSlice from '../store/currentUser/slice';

let tenantId:(string | null) = null;
const interactionServer = store => next => action => {
  switch (action.type) {
    case currentUserSlice.asyncActions.getPermission.fulfilled.type:
      tenantId = action.payload.tenantId;
      store.dispatch(applicationSlice.actions.initialized());
      break;
    case applicationSlice.actions.initialized.type:
      store.dispatch(applicationSlice.asyncActions.connect());
      break;
    case applicationSlice.asyncActions.connect.fulfilled.type:
      store.dispatch(interactionSlice.asyncActions.listInteractions({tenantId : tenantId!}));
      break;
    case currentInteractionSlice.asyncActions.assignInteraction.fulfilled.type:
      store.dispatch(currentInteractionSlice.asyncActions.joinInteraction(action.payload.interactionId));
      break;
    case currentInteractionSlice.asyncActions.joinInteraction.fulfilled.type:
      store.dispatch(currentInteractionSlice.asyncActions.initializeInteraction(action.payload as InitializeInteractionActionPayload));
      break;
    case currentInteractionSlice.asyncActions.initializeInteraction.fulfilled.type:
      store.dispatch(currentInteractionSlice.asyncActions.startInteraction());
      break;
  }
  
  return next(action);
};

export default interactionServer;
