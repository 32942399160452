import * as signalR from '@microsoft/signalr';
import { ITokenProvider } from './IAuthenticationService';
import { ISignalrConnectionProvider } from './ISignalrConnectionProvider';


export class SignalrConnectionProvider implements ISignalrConnectionProvider {
    private url: string;
    private connection: (signalR.HubConnection | null) = null;
    private tokenProvider: ITokenProvider;

    constructor(url: string, tokenProvider: ITokenProvider) {
        this.url = url;
        this.tokenProvider = tokenProvider;

        let builder = new signalR.HubConnectionBuilder();
        if (tokenProvider.canGetToken() === true)
        {
            builder = builder.withUrl(this.url, {
                accessTokenFactory: () => this.tokenProvider.getAccessToken()
            });
        } else {
            builder = builder.withUrl(this. url);
        }
        
        this.connection = builder.build();
    }

    getConnection(): signalR.HubConnection {
        if (this.connection == null) {
            throw new Error("Connection is null");
        }

        return this.connection;
    }

    async start(): Promise<void> {
        this.connection!.onclose(() => setTimeout(() => this.connection!.start(), 5000));
        await this.connection!.start();
    }
}
