import * as React from 'react';
import Stream from './Stream'
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import { IChatService } from '../services/IChatService';
import { useSelector } from "react-redux";
import { AppState } from '../store';

const useStyles = makeStyles((theme) => ({
    highlightedStream: {
        width: `100%`,
        height: `calc(100vh - 64px - 300px)`,
        maxHeight: `calc(100vh - 64px - 300px)`,
        backgroundColor: 'black'        
    }
}));

const HighlightStream: React.FC<{chatService: IChatService}> = ({chatService}) => {
    const classes = useStyles();
    const highlightStream = useSelector((state: AppState) => state.currentInteraction.highlightStream);

    const getStream = (highlightStream:{peerId: string, streamId: string} | null) => {
        if (highlightStream == null){
            return null;
        }

        let stream:MediaStream | null = null;
        const highlightMediaStreamInfo = (highlightStream.peerId == 'self' ? chatService.getSelfStreamsInfo() : chatService.getPeerStreamsInfo(highlightStream.peerId));
        if (highlightMediaStreamInfo?.audioVideoStream?.id == highlightStream.streamId) {
            stream = highlightMediaStreamInfo.audioVideoStream;
        } else if (highlightMediaStreamInfo?.screenStream?.id == highlightStream.streamId) {
            stream = highlightMediaStreamInfo.screenStream;
        }

        if (stream == null) {
            return null;
        }
        
        let returnStream = new MediaStream();
        for (let currentTrack of stream.getVideoTracks()) {
            returnStream.addTrack(currentTrack);
        }

        return returnStream;
    }

    return (
        <Box className={classes.highlightedStream}>
            <Stream id="highlighted-stream" stream={getStream(highlightStream)} />
        </Box>
    );
}

export default HighlightStream;