import BaseHttpService from './BaseHttpService'
import { IAgentService, Agent } from './IAgentService';
import { ITokenProvider } from './IAuthenticationService';

export class AgentService extends BaseHttpService implements IAgentService
{
    constructor(apiUrl: string, tokenProvider: ITokenProvider) {
        super(apiUrl, tokenProvider);
    }
    
    async getAgents(tenantId:string): Promise<Agent[]> {
        const response = await fetch(`${this.apiUrl}/tenants/${tenantId}/agents`, {    
            method: "GET",
            headers: await this.getAuthorizationHeader()
        });
        return (await response.json()) as Agent[];
    }

    async addAgent(agent: Agent): Promise<Agent> {
        const response = await fetch(`${this.apiUrl}/tenants/${agent.tenantId}/agents`, {       
            method: "POST",
            headers: { 'Content-Type': 'application/json', ...(await this.getAuthorizationHeader())},
            body: JSON.stringify({
              tenantId: agent.tenantId,
              firstName: agent.firstName,
              lastName: agent.lastName,
              fullName: agent.fullName,
              nickName: agent.nickName,
              emailAddress: agent.emailAddress,
              password: agent.password,
              categoryIds: agent.categoryIds
            })
          });
        return (await response.json()) as Agent;
    }

    async updateAgent(agent: Agent): Promise<Agent> {
        const response = await fetch(`${this.apiUrl}/tenants/${agent.tenantId}/agents/${agent.id}`, {    
            method: "PUT",
            headers: { 'Content-Type': 'application/json', ...(await this.getAuthorizationHeader())},
            body: JSON.stringify({
              tenantId: agent.tenantId,
              firstName: agent.firstName,
              lastName: agent.lastName,
              fullName: agent.fullName,
              nickName: agent.nickName,
              emailAddress: agent.emailAddress,
              password: agent.password,
              categoryIds: agent.categoryIds
            })
          });
        return (await response.json()) as Agent;
    }
}