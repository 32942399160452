const config = 
{
    "auth": {
        "domain": "webassist.au.auth0.com",
        "clientId": "CqeXiJkPC0CVnX3nwVp122g7CekxXhD7",
        "interactionServer": {
            "audience": "https://interactionserver.webassist.com.my"
        },
        "loggedOutReturnUrl": "https://agentdashboard.webassist.com.my/loggedout"
    },
    "interactionServer": {
        "url": "https://interactionserver.webassist.com.my/interactionHub",
        "apiUrl": "https://interactionserver.webassist.com.my/api"
    },
    "iceServers": [
        { urls: 'stun:stun.l.google.com:19302' }
    ]
}
export default config;