import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { AddChatMessageActionPayload, currentInteractionSlice } from "../store/currentInteraction/slice";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { AppState } from '../store';

import ChatMessagesContainer from './ChatMessagesContainer';

const useStyles = makeStyles((theme) => ({
    interactionContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    header: {
        height: '30px'
    },
    chatEntryContainer: {
        marginBottom: "auto",
        height: '50px',
        display: 'flex',
        flexDirection: 'row'
    },
    chatInput: {
        flexGrow: 1
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 10px'
    },
    submittedTime: {
        textAlign: 'right',
        flexGrow: 1
    },
    subject: {

    }
}));

export default function InteractionChat(){
    const classes = useStyles();
    const { interactionId, events } = useSelector((state: AppState) => state.currentInteraction);
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            sendChatMessage();
        }
    }
    
    const handleSubmit = (event) => {
        sendChatMessage();
        event.preventDefault();
    }

    const sendChatMessage = () => {
        if (message && message.trim() != '') {
            var payload: AddChatMessageActionPayload = {
                interactionId: interactionId!,
                message: message
            };
            dispatch(currentInteractionSlice.asyncActions.addChatMessage(payload));
            setMessage('');
        }
    }

    return (
        <Box className={classes.interactionContainer}>
            <ChatMessagesContainer events={events} />    
            <Box className={classes.chatEntryContainer}>
                <TextField 
                    margin="dense" 
                    variant="outlined"
                    size="small"
                    label="Message" 
                    onChange={(event) => setMessage(event.target.value)} 
                    onKeyPress={handleKeyPress}
                    value={message} 
                    className={classes.chatInput} />
                <Button onClick={handleSubmit}>Send</Button>
            </Box>
        </Box>
    );
}