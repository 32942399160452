import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IInteractionSessionService } from '../../services/IInteractionSessionService'
import { ISignalrConnectionProvider } from '../../services/ISignalrConnectionProvider';

export interface ApplicationState {
    isConnectedToServer: boolean;
    isInitialized: boolean;
}

const connect = createAsyncThunk<void, void>(
    "application/serverConnection/start",
    async (arg: void, thunkApi: any) => {
        const { signalrConnectionProvider }: { signalrConnectionProvider: ISignalrConnectionProvider} = thunkApi.extra;
        const { interactionSessionService }: { interactionSessionService: IInteractionSessionService} = thunkApi.extra;
        await interactionSessionService.initialize(thunkApi.dispatch);
        await signalrConnectionProvider!.start();
    });

const applicationSliceTemp = createSlice({
    name: 'application',
    initialState: {
        isConnectedToServer: false,
        isInitialized: false
    } as ApplicationState,
    reducers: {
        initialized: (state, action:PayloadAction<void>) => {
            state.isInitialized = true;
        },
        dashboardLoaded: (state, action:PayloadAction<void>) => {

        }
    },
    extraReducers: builder => {
        builder.addCase(connect.fulfilled, (state, action) => {
            state.isConnectedToServer = true;
        });
    }
});

export const applicationSlice = Object.assign(applicationSliceTemp, { asyncActions: { connect } });
export default applicationSlice;