import * as SimplePeer from "simple-peer";
import { IChatService, ChatStreams, ChatStreamState } from "./IChatService";
import { ISignalrConnectionProvider } from "./ISignalrConnectionProvider";
import { currentInteractionSlice } from '../store/currentInteraction/slice';
import * as process from 'process';

(window as any).process = process;

declare global {
    interface Navigator {
        webkitGetUserMedia: any;
        mozGetUserMedia: any;
    }
}
export class SimplePeerChatService implements IChatService {
    private connectionProvider: ISignalrConnectionProvider;
    private static readonly BackChannelMethod = "SimplePeer_Message";

    private audioStream: MediaStream;
    private videoStream: MediaStream;
    private audioVideoStream: MediaStream;
    private screenStream: MediaStream | null = null;

    private peer: SimplePeer.Instance;

    private remotePeerId: string | null = null;
    private isInitiator: boolean = false;
    private peerMediaStreams: Map<string, MediaStream>;
    private peerChatStreamState: Map<string, ChatStreamState>;
    private tenantId: string;
    private interactionId: string;
    private dispatch: any;
    private iceServers: Array<RTCIceServer>;

    constructor(connectionProvider: ISignalrConnectionProvider, iceServers: Array<RTCIceServer>) {
        this.connectionProvider = connectionProvider;
        this.peerMediaStreams = new Map<string, MediaStream>();
        this.peerChatStreamState = new Map<string, ChatStreamState>();
        this.iceServers = iceServers;

        var self = this;
        const connection = this.connectionProvider!.getConnection();
        connection.on("ChatStreamStateChanged", (peerId, chatStreamState) => {
            console.log('chat stream state changed!!', peerId, chatStreamState);
            self.peerChatStreamState.set(peerId, chatStreamState);
            self.dispatchPeerChatStreamStateChanged(peerId);
        });

        connection.on(SimplePeerChatService.BackChannelMethod, (message) => {
            let data;
            try {
                data = JSON.parse(message);
                if (this.remotePeerId === null && this.isInitiator == false) {
                    this.remotePeerId = data['src'];
                }
            } catch (e) {
                console.error("Invalid message received", e);
                return;
            }

            self.peer.signal(data);
        });
    }

    private dispatchPeerChatStreamStateChanged(peerId: any) {
        const streamState = this.peerChatStreamState.get(peerId);
        if (streamState == null) {
            console.error(`No streams info found for peer ${peerId}.`, this.peerChatStreamState);
            return;
        }
        const audioVideoStreamId = streamState.audioVideoStreamId;
        const screenStreamId = streamState.screenStreamId;

        const isValidAudioVideoStream = audioVideoStreamId == null ? true : this.getPeerStream(audioVideoStreamId) !== undefined;
        const isValidScreenStream = screenStreamId == null ? true : this.getPeerStream(screenStreamId) !== undefined;
        if (isValidAudioVideoStream && isValidScreenStream) {
            this.dispatch(currentInteractionSlice.actions.peerChatStreamStateChanged({ peerId: peerId, chatStreamState: streamState }));
            return;
        }

        console.warn(`Invalid streams.`, streamState, this.peerMediaStreams);
    }

    private getPeerStream(streamId: string | null):MediaStream | undefined {
        if (streamId == null ) {
            return undefined;
        }

        return this.peerMediaStreams.get(streamId!);
    }

    toggleAudio(isEnabled: boolean) {
        this.audioStream.getAudioTracks()[0].enabled = isEnabled;
        this.sendStreamsInfo();
        return Promise.resolve(isEnabled);
    }

    toggleVideo(isEnabled: boolean) {
        this.videoStream.getVideoTracks()[0].enabled = isEnabled;
        this.sendStreamsInfo();
        return Promise.resolve(isEnabled);
    }

    async toggleScreen(isEnabled: boolean) {
        if (this.screenStream !== null && !isEnabled) {
            this.screenStream.getTracks().forEach(function (track) {
                track.stop();
            });
            this.screenStream = null;
            this.sendStreamsInfo();
        } else if (this.screenStream === null && isEnabled) {
            // @ts-ignore
            const screenStream = await navigator.mediaDevices.getDisplayMedia(); 
            const self = this;
            
            screenStream.getVideoTracks()[0].onended = function () {
                self.screenStream = null;
                self.sendStreamsInfo();
              };
            this.screenStream = screenStream;
            self.peer.addStream(screenStream);
            this.sendStreamsInfo();
        }
        return Promise.resolve(isEnabled);
    }

    async initialize(dispatch: any, connectionInformation: any, tenantId: string, interactionId: string): Promise<void> {
        this.tenantId = tenantId;
        this.dispatch = dispatch;
        this.interactionId = interactionId;
        if (connectionInformation && connectionInformation["ContactConnectionId"]) {
            this.remotePeerId = connectionInformation["ContactConnectionId"];
            this.isInitiator = true;
        } else {
            this.setupPeer();
        }

        await this.setupAudioVideoStream();
    }

    private async setupAudioVideoStream() {
        this.audioStream = await this.getUserMediaAsync({ audio: true, video: false });
        this.videoStream = await this.getUserMediaAsync({ audio: false, video: true });

        this.audioVideoStream = new MediaStream();
        this.audioVideoStream.addTrack(this.videoStream.getVideoTracks()[0]);

        // const createDummyTrack = ({width = 1920, height = 1080} = {}) => {
        //     let canvas = Object.assign(document.createElement("canvas"), {width, height});
        //     canvas.getContext('2d')!.fillRect(0, 0, width, height);
        //     let stream = canvas.captureStream();
        //     return stream.getVideoTracks()[0];
        // }
        // const dummyTrack = createDummyTrack({width: 640, height:480});
        // dummyTrack.enabled = false;
        this.audioVideoStream.addTrack(this.audioStream.getAudioTracks()[0]);
    }

    private setupPeer(): void {
        this.peer = new SimplePeer.default({
            config: { iceServers: this.iceServers },
            initiator: this.isInitiator
        });
        const peer = this.peer;
        var self = this;
        peer.on("connect", () => {
            peer.addStream(this.audioVideoStream);
            this.sendStreamsInfo();
            this.dispatch(currentInteractionSlice.actions.highlightStreamUpdated({peerId: "self", streamId: this.audioVideoStream.id}));
        });

        peer.on("error", err => console.error("error", err));

        peer.on("signal", data => {
            data["dst"] = self.remotePeerId;
            self.connectionProvider.getConnection()!.invoke(SimplePeerChatService.BackChannelMethod, JSON.stringify(data));
        });

        peer.on("stream", (stream: MediaStream) => {         
            this.peerMediaStreams.set(stream.id, stream);   
            self.dispatchPeerChatStreamStateChanged(self.remotePeerId);
        });
        peer.on('close', async () => {
            await this.end();
        })

        // peer.on("track", (track: MediaStreamTrack, stream: MediaStream) => {
        //     console.log("GOT track! ", track, stream);
        //     this.peerMediaTracks.set(track.id, track);
        // });
    }

    private getUserMediaAsync(constraints: MediaStreamConstraints): Promise<MediaStream> {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            return navigator.mediaDevices.getUserMedia(constraints);
        }

        return new Promise((resolve, reject) => {
            const nav = navigator as any;
            const getUserMedia = nav.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
            getUserMedia(constraints, function (stream) {
                resolve(stream);
            }, function (err) {
                reject(`Failed to get local stream '${err}'.`);
            });
        });
    }

    getVideoStream(): MediaStream {
        return this.videoStream;
    }

    getAudioStream(): MediaStream {
        return this.audioStream;
    }

    getScreenStream(): MediaStream {
        return this.screenStream!;
    }

    getSelfStreamsInfo(): ChatStreams {
        return {
            audioVideoStream: this.audioVideoStream,
            screenStream: this.screenStream ?? null
        }
    }

    getPeerStreamsInfo(peerId: string): ChatStreams {        
        if (!this.peerChatStreamState.has(peerId)){
            return {
                audioVideoStream: null,
                screenStream: null
            };
        }
        const streamsInfo = this.peerChatStreamState.get(peerId);
        const audioVideoStream = this.getPeerStream(streamsInfo!.audioVideoStreamId);
        let screenStream:MediaStream | null = null;
        if (streamsInfo!.screenStreamId) {
            const stream = this.getPeerStream(streamsInfo!.screenStreamId);
            if (stream !== undefined) {
                screenStream = stream;
            }
        }
        return {
            audioVideoStream: audioVideoStream !== undefined ? audioVideoStream : null,
            screenStream: screenStream
        }
    }

    // async setScreenshare(): Promise<void> {
    //     var mediaDevices = navigator.mediaDevices as any;
    //     this.screenStream = await mediaDevices.getDisplayMedia({ audio: false });
    //     //this.mediaConnection.peerConnection.addTrack(this.screenStream!.getTracks()[0], this.screenStream! );
    // }

    async call(): Promise<void> {
        if (!this.isInitiator) {
            throw new Error("Cannot call unless this is an initiator.");
        }
        this.setupPeer();
    }

    async end(): Promise<void> {
        this.peer.destroy();
        if (this.audioStream){
            this.stopAllTracks(this.audioStream);
        }
        if (this.videoStream){
            this.stopAllTracks(this.videoStream);
        }
        if (this.audioVideoStream){
            this.stopAllTracks(this.audioVideoStream);
        }
        if (this.screenStream){
            this.stopAllTracks(this.screenStream);
        }
    }

    private stopAllTracks(stream: MediaStream) {
        stream.getTracks().forEach(function(track) {
            track.stop();
        });
    }

    private async sendStreamsInfo() {
        var chatStreamState: ChatStreamState = this.getChatStreamState();        
        this.dispatch(currentInteractionSlice.actions.selfChatStreamStateChanged({chatStreamState: chatStreamState }));
        await this.connectionProvider.getConnection().invoke("ChatStreamStateChanged", this.tenantId, this.interactionId, chatStreamState);
    }

    private getChatStreamState(): ChatStreamState {
        return {
            audioVideoStreamId: this.audioVideoStream != null ? this.audioVideoStream.id: null,
            screenStreamId: this.screenStream != null ? this.screenStream.id : null,            
            isAudioEnabled: this.audioStream !== null && this.audioStream.getAudioTracks().length > 0 && this.audioStream.getAudioTracks()[0].enabled,
            isVideoEnabled: this.videoStream !== null && this.videoStream.getVideoTracks().length > 0 && this.videoStream.getVideoTracks()[0].enabled,
            isScreenSharing: this.screenStream !== null && this.screenStream.getVideoTracks().length > 0 && this.screenStream.getVideoTracks()[0].enabled,
        };
    }
}
