import React from 'react';
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AppState } from '../store';

export default function AgentSummaryTable() {
    const { agentSummary } = useSelector((state: AppState) => state.report);
    
    let rows:JSX.Element[] = [];
    if (agentSummary && agentSummary.length > 0){
        for(let current of agentSummary){
            rows.push(<TableRow key={current.agentId}>
                <TableCell>{current.fullName}</TableCell>
                <TableCell>{current.totalHandlingTime.toFixed(2)}</TableCell>
                <TableCell>{current.averageHandlingTime.toFixed(2)}</TableCell>
                <TableCell>{current.percentageHandled.toFixed(2)}</TableCell>
            </TableRow>)
        }
    }
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Total Handling Time (min)</TableCell>
                        <TableCell>Average Handling Time (min)</TableCell>
                        <TableCell>Percentage Handled</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </>
    );
}