import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InteractionState, InteractionStatus, resetToInitialState } from "./base";
import { 
    IAgentInteractionSessionService, 
    ResolveInteractionRequest } from '../../services/IAgentInteractionSessionService';
import { IChatService } from '../../services/IChatService';

export interface AssignInteractionActionResult {
    interactionId: string;
}

export interface TransferInteractionActionPayload {
    tenantId: string;
    interactionId: string;
    newCategoryId: string;
}

export interface TransferInteractionActionResult {
    tenantId: string;
    interactionId: string;
    newCategoryId: string;
}

export interface ResolveInteractionActionPayload {
    tenantId: string;
    interactionId: string;
    status: string;
    resolution: string;
}

export interface ResolveInteractionActionResult {
    tenantId: string;
    interactionId: string;
    status: string;
    resolution: string;
}

const assignInteraction = createAsyncThunk(
    "interactions/session/assign",
    async (arg:string, thunkApi:any): Promise<AssignInteractionActionResult> => {        
        const { interactionSessionService }: { interactionSessionService: IAgentInteractionSessionService} = thunkApi.extra;
        await interactionSessionService.assignToSelf(arg);
        return {
            interactionId: arg
        };
    });

const resolveInteraction = createAsyncThunk(
    "interactions/session/resolve",
    async (arg:ResolveInteractionActionPayload, thunkApi: any): Promise<ResolveInteractionActionResult> => {        
        const { interactionSessionService, chatService }: { interactionSessionService: IAgentInteractionSessionService, chatService: IChatService } = thunkApi.extra;
        const result = await interactionSessionService.resolve(arg as ResolveInteractionRequest);
        await chatService.end();
        return result as ResolveInteractionActionResult;
    });

const transferInteraction = createAsyncThunk(
    "interactions/session/transfer",
    async (arg:TransferInteractionActionPayload, thunkApi: any): Promise<TransferInteractionActionResult> => {
        const { interactionSessionService, chatService }: { interactionSessionService: IAgentInteractionSessionService, chatService: IChatService } = thunkApi.extra;
        const result = await interactionSessionService.transfer(arg as TransferInteractionActionPayload);
        await chatService.end();
        return result as TransferInteractionActionResult;
    });

const extensionExtraReducers = (builder: ActionReducerMapBuilder<InteractionState>) => {
    builder.addCase(resolveInteraction.fulfilled, (state, action) => {
        resetToInitialState(state);
    });
    builder.addCase(transferInteraction.fulfilled, (state, action) => {
        resetToInitialState(state);
    });

    builder.addCase(assignInteraction.fulfilled, (state, action) => {
        state.interactionId = action.payload.interactionId;
        state.status = InteractionStatus.InProgress;
    });
}

const extensionAsyncActions = () => {
   return {
    resolveInteraction,
    transferInteraction,
    assignInteraction,
   }
}

export {
    extensionExtraReducers,
    extensionAsyncActions,
}