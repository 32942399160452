import * as React from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSlice } from "../store/currentUser/slice";
import sidebarSlice from "../store/sidebar/slice";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';

import InteractionSearchForm from './InteractionSearchForm';
import { AppState } from '../store';
import ConnectionStatus from './ConnectionStatus';

const useStyles = (drawerWidth) => makeStyles(theme => ({
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	hide: {
		display: "none"
	},
	title: {
		flexGrow: 1
	}
}))();
export default function PageHeader() {
	const { drawerWidth } = useSelector((state: AppState) => state.ui);
	const { isSidebarExpanded } = useSelector((state: AppState) => state.sidebar);
	const { isAuthenticated, profilePictureLink, name } = useSelector((state: AppState) => state.currentUser);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const classes = useStyles(drawerWidth);
	const open = Boolean(anchorEl);
	const dispatch = useDispatch();

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return <>
        <AppBar position="fixed"
            className={clsx(classes.appBar, { [classes.appBarShift]: isSidebarExpanded })}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => dispatch(sidebarSlice.actions.sidebarOpen())}
                    edge="start"
                    className={clsx(classes.menuButton, isSidebarExpanded && classes.hide)}
                    size="large">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap className={classes.title}>
                    WebAssist
                </Typography>
                <ConnectionStatus />
                {isAuthenticated && (
                    <>
                        <InteractionSearchForm />
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            edge="end"
                            size="large">
                            <Avatar alt={name == null ? undefined : name!} src={profilePictureLink == null ? undefined : profilePictureLink!} />
                        </IconButton>
                        <Menu id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}>
                            <MenuItem onClick={() => dispatch(currentUserSlice.asyncActions.logout())}>Log Out</MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    </>;
}