import BaseHttpService from './BaseHttpService'
import { ITokenProvider } from './IAuthenticationService';
import { IReportService, ReportQuery, Report } from './IReportService';

export class ReportService extends BaseHttpService implements IReportService {
  constructor(apiUrl: string, tokenProvider: ITokenProvider) {
    super(apiUrl, tokenProvider);
  }

  async getReport(reportQuery: ReportQuery): Promise<Report> {
    const startDateString = encodeURIComponent(reportQuery.startDate.toISOString());
    const endDateString = encodeURIComponent(reportQuery.endDate.toISOString());
    const url = `${this.apiUrl}/tenants/${reportQuery.tenantId}/reports?startDate=${startDateString}&endDate=${endDateString}`;
    const response = await fetch(url, {
      method: "GET",
      headers: await this.getAuthorizationHeader()
    });
    return (await response.json()) as Report;
  }

}